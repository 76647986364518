import { Box, Card, DialogContent, IconButton, List, ListItem, ListItemButton, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useState } from "react";
import { EventSettingsContext } from "../..";
import NoRepliesGif from "../../../../../../../../assets/no-replies.gif";
import { downloadEventCheckIns, getCheckInRecords, getTotalCheckInCount } from "../../../../../../../../services/wEvents/eventGuests";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import moment from 'moment';
import { toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from "@mui/icons-material/Info";
import TicketCheckInsDetailsDialog from "./TicketCheckInsDetailsDialog";
import CheckInRecordsWithoutTickets from "./CheckInRecordsWithoutTickets";

const EventCheckInRecords = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const { per_page_records, totalCheckInRecords, checkInRecords, setCheckInRecords, checkedInTickets, setTotalCheckInCount } = useContext(EventSettingsContext);
    const eventData = useSelector(eventDetails);
    const [activeTicketId, setActiveTicketId] = useState(null);

    const [viewDialog, setViewDialog] = useState(false);

    const handleEventCheckInSumaryDetails = (ticketId) => {
        getTotalCheckInCount({ eventId: eventData?.id, ticketId: ticketId }).then((res) => {
            setTotalCheckInCount(res.data)
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleTicketCheckInDetails = (ticketId) => {
        setViewDialog(true);
        getCheckInRecords({ eventId: eventData?.id, ticketId: ticketId, page: 1 }).then((res) => {
            console.log("res", res.data);
            setCheckInRecords(res.data.data);
            totalCheckInRecords.current = res.data.total
            per_page_records.current = res.data.per_page
        }).catch((err) => {
            console.log(err);
        })
    };

    const handleCheckInsDownload = (ticketId, ticketName) => {
        downloadEventCheckIns(eventData?.id, ticketId).then((result) => {
            console.log(result);
            const fileBlob = new Blob([result.data], { type: 'text/csv' });
            const downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            const fileUrl = URL.createObjectURL(fileBlob);

            // Create a link to download the file
            downloadLink.href = fileUrl;
            downloadLink.download = `${eventData.title}-${ticketName}-Check In Records-${moment().format('D-M-YYYY HH-mm')}.csv`;
            // Add the link to the DOM and click it
            downloadLink.click();

            // Clean up the URL object
            URL.revokeObjectURL(fileUrl);
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
                position: "top-right",
                theme: "dark"
            });
        });
    }

    return (
        <>
            {
                checkedInTickets ?
                    <>
                        {checkedInTickets.length === 0 ? (
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ mt: 5 }}>
                                <Box
                                    component="img"
                                    src={NoRepliesGif}
                                    width={isSmallDevice ? "90%" : "60% !important"}
                                    height={isSmallDevice ? "15rem" : "17rem"}
                                />
                            </Stack>
                        ) :
                            <>
                                <DialogContent dividers sx={{
                                    p: { xs: '0rem', md: '0rem' },
                                    height: { xs: '26.5rem', md: '31.5rem' }
                                }}
                                >
                                    <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                                        <List sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-evenly',
                                            gap: '1.5rem',
                                            p: '1rem',
                                        }}>
                                            {checkedInTickets.map((ticket, index) => (
                                                <ListItem key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flex: 1,
                                                        minWidth: '45%',
                                                        minHeight: 200,
                                                        backgroundColor: '#E5E4E9',
                                                        p: '.5rem',
                                                        px: '1rem',
                                                        borderRadius: '.5rem',
                                                        boxShadow: '2px 2px 5px rgba(0,0,0,0.3)',
                                                        transition: '250ms linear scale',
                                                        cursor: 'pointer',
                                                        '@media (max-width: 560px)': {
                                                            minWidth: '100%', // Ensure the width is 100% when max width is 760px
                                                        },
                                                        '&:hover': {
                                                            scale: '1.05',
                                                            backgroundColor: '#E5E4E9',
                                                        }
                                                    }}
                                                >
                                                    <Stack
                                                        direction='column'
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', mb: '1rem' }}>
                                                            {ticket.ticket_name}
                                                        </Typography>

                                                        {(ticket?.total_check_in || ticket?.total_purchase_ticket) &&
                                                            <Box sx={{ fontSize: '1rem', display: 'flex', flexDirection: 'column', mb: 'auto' }}>
                                                                <Typography align="left">Entry : {ticket?.total_check_in}/{ticket?.total_purchase_ticket}</Typography>
                                                                {ticket?.total_facilities_check_in?.facilities.map((facility) => {
                                                                    return <Typography align="left">{facility.facility_name} : {facility.check_in_count}/{facility.facilities_taken_by_user}</Typography>
                                                                })}
                                                            </Box>}
                                                    </Stack>
                                                    <Stack direction='row' alignItems='center' alignSelf={'flex-start'} sx={{ ml: '.5rem' }}>
                                                        <Tooltip title="View" arrow>
                                                            <IconButton onClick={() => {
                                                                setActiveTicketId(ticket.ticket_id)
                                                                handleTicketCheckInDetails(ticket.ticket_id);
                                                                handleEventCheckInSumaryDetails(ticket.ticket_id);
                                                            }}>
                                                                <InfoIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Download" arrow>
                                                            <IconButton onClick={() => handleCheckInsDownload(ticket.ticket_id, ticket.ticket_name)}>
                                                                <DownloadIcon sx={{ color: '#1F274A', fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </DialogContent>
                            </>
                        }
                    </> :
                    (checkInRecords ?
                        <CheckInRecordsWithoutTickets /> :
                        <Stack flexDirection='row' gap='1.5rem' flexWrap='wrap'
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                mt: '10%'
                            }}
                        >
                            {[1, 2].map((_, index) => (
                                <Skeleton
                                    key={index}
                                    sx={{
                                        mx: '0.5rem !important',
                                        p: '0rem !important',
                                        transform: 'scale(1,2)',
                                    }}
                                    width='40%'
                                    height='5rem'
                                    animation="wave"
                                />))
                            }
                        </Stack>
                    )
            }
            {viewDialog ? <TicketCheckInsDetailsDialog activeTicketId={activeTicketId} setViewDialog={setViewDialog} /> : null}
        </>
    )
};

export default EventCheckInRecords;
