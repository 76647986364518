import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Box, Button, Collapse, DialogActions, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InvitedUserMessageStatus from '../../../../../../../../../../components/InvitedUserMessageStatus';
import InvitedUserPagination from '../../../../../../../../../../components/InvitedUserPagination';
import MuiTabPanel from "../../../../../../../../../../components/muiTabPanel";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { getInvitationDetails } from "../../../../../../../../../../services/wEvents/eventGuests";

const OneInvitationDetails = ({ invitationType, details, setDetails, setShowDetails, tabStyles }) => {
    const eventData = useSelector(eventDetails);
    const [tabValue, setTabValue] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const tabProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const msgTypes = details?.message?.map(m => m.type);

    const fetchOneInvitationDetails = (page = 1) => {
        setDetails({ ...details, users: null });
        getInvitationDetails({ eventId: eventData?.id, invitationId: details.id, page: page }).then((res) => {
            setDetails(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        fetchOneInvitationDetails();
    }, []);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#FF8359"
                    }
                }}
                sx={{
                    backgroundColor: '#FFEBE4',
                    maxWidth: { sm: '100%' }
                }}
            >
                <Tab {...tabProps(0)} className={tabStyles.root} label='Guests' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
                <Tab {...tabProps(1)} className={tabStyles.root} label='Message' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
            </Tabs>
            <Box sx={{ px: '0.5rem' }}>
                <MuiTabPanel value={tabValue} index={0}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {(details.users?.data ?? Array.from(new Array(3))).map((g, index) => (
                            <React.Fragment key={index}>
                                <OneGuestBox g={g} msgTypes={msgTypes} invitationType={invitationType} />
                            </React.Fragment>
                        ))}
                    </List>
                </MuiTabPanel>
                <MuiTabPanel value={tabValue} index={1}>
                    <Stack spacing={2} >
                        {details.message?.map((m, i) => (
                            <React.Fragment key={i}>
                                <OneMessageBox m={m} />
                            </React.Fragment>
                        ))}
                    </Stack>
                </MuiTabPanel>
                <Divider sx={{ my: 1 }} />
                <DialogActions sx={{
                    pt: 0,
                    justifyContent: tabValue === 0 ? 'space-between' : 'flex-end',
                    alignItems: 'center'
                }}>
                    {tabValue === 0 ?
                        <InvitedUserPagination
                            activePage={activePage}
                            setActivePage={setActivePage}
                            totalUsers={details?.users?.total ?? 0}
                            per_page_records={details?.users?.per_page}
                            fetchOneInvitationDetails={fetchOneInvitationDetails}
                        />
                        : null
                    }
                    <Button type="submit" color='warning' variant="outlined"
                        onClick={() => setShowDetails(false)}
                        sx={{
                            boxShadow: 'none'
                        }}>
                        Done
                    </Button>
                </DialogActions>
            </Box>
        </>
    )
};

const OneGuestBox = ({ g, msgTypes, invitationType }) => {
    const [activeItemKey, setActiveItemKey] = useState(null);
    const handleClick = (id) => {
        if (id === activeItemKey) {
            setActiveItemKey(null);
        } else {
            setActiveItemKey(id);
        }
    };

    return (
        <>
            <ListItem
                onClick={() => invitationType === 'completed' ? handleClick(g?.id) : null}
                sx={{
                    backgroundColor: activeItemKey === g?.id ? '#F1F1F1' : 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}>
                {g ?
                    <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: '#FFEBE4' }} />
                    </ListItemAvatar> :
                    <Skeleton variant="circular" width={40} height={40} />
                }
                <ListItemText
                    primary={g ? g.full_name : <Skeleton width='90%' height='1.75rem' sx={{ ml: 2 }} />}
                    secondary={g ? `+ ${g?.dialing_code} ${g?.mobile}` : <Skeleton width='60%' sx={{ ml: 2 }} />} />
                {invitationType === 'completed' ?
                    (activeItemKey === g?.id ? <ExpandLess /> : <ExpandMore />)
                    : null
                }
            </ListItem>
            <Collapse in={activeItemKey === g?.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <InvitedUserMessageStatus msgTypes={msgTypes} invitedUserDetails={g} />
                </List>
            </Collapse>
        </>
    )
}

const OneMessageBox = ({ m }) => {
    return (
        <>
            <Box>
                <Typography>{_.capitalize(m.type)}</Typography>
                {m.type === 'email' ? <Editor
                    apiKey="dsiirqxfbbshbtto49hmn3aplhh22hnefwjei883n0nyg0no"
                    initialValue={m.message}
                    disabled={true}
                    init={{
                        height: '50vh',
                        readonly: 1,
                        menubar: false,
                        toolbar: false,
                    }}
                /> :
                    <TextField
                        fullWidth
                        multiline
                        color="warning"
                        defaultValue={m.message}
                        inputProps={{ readOnly: true }}
                        sx={{ mt: 0.5 }}
                    />
                }
            </Box>
        </>
    )
}

export default OneInvitationDetails;
