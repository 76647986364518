import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import LoginDialog from "../../components/LoginDialog";
import { clear_event_details, eventDetails, update_event_details, update_event_id } from '../../redux/slices/eventDataSlice';
import { userDetails, userStatus } from "../../redux/slices/userDataSlice";
import { getEventDetails } from "../../services/wEvents/event";
import { getGuestDetails } from "../../services/wEvents/eventGuests";
import { PAGE_PATHS } from '../../utils/constants/page-path.constants';
import { ALL_PERMISSION_ROLES } from "../../utils/constants/roles";
import EventDetailCards from './components/EventDetailCards';
import EventShortcuts from "./components/EventShortcuts";
import EventMenubar from './components/Menubar';
import './event-details.scss';
import { Box } from "@mui/system";
import { clear_sidebar_shortcuts, set_sidebar_shortcuts } from "../../redux/slices/sidebarDataSlice";

export const EventDetailsContext = React.createContext();

const EventDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, guestId, commonEventId, exhibitorId, subSection } = useParams();
    const eventData = useSelector(eventDetails);

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const registrationTab = eventData?.tabs?.find((t) => t.title === 'Registration');
    const registrationTabIndex = eventData?.tabs?.indexOf(registrationTab);
    const handleClick = () => {
        setTabValue(registrationTabIndex); //Set registration tab index on btn click
    }
    const isLoggedIn = useSelector(userStatus);
    const userData = useSelector(userDetails);
    const [loginDialog, setLoginDialog] = useState(false);
    const [guestData, setGuestData] = useState(null);

    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);

    const sidebarShortcuts = [
        { 'Overview': 'event-overview' },
        { 'Event Reports': 'event-reports' },
        // { 'Microsite Builder': 'microsite-builder' },
        // { 'RSVP': 'rsvp' },
        // ...(eventData?.has_registration ? [{ 'Registration Form': 'registration-form' }] : []),
        // { 'Tickets': 'tickets' },
        // ...(eventData?.is_poll ? [{ 'Polls': 'polls' }] : []),
        // { 'Invitations': 'invitations' },
        // { 'Reminders': 'reminders' },
        // { 'Check-ins': 'check-ins' },
        // { 'Payments': 'payments' },
    ];

    useEffect(() => {
        if (guestId) {
            getEventDetails({ guestId: guestId }).then((res) => {
                dispatch(update_event_details(res.data.data))
                if (!isLoggedIn) {
                    getGuestDetails({ eventId: res.data.data?.id, uuid: guestId }).then((result) => {
                        setLoginDialog(true);
                        setGuestData(result.data.data);
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }).catch((err) => {
                console.log(err);
                navigate(`/${PAGE_PATHS.DASHBOARD}`);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                })
            })
        } else if (exhibitorId) {
            getEventDetails({ guestId: exhibitorId }).then((res) => {
                dispatch(update_event_details(res.data.data))
                if (!isLoggedIn) {
                    getGuestDetails({ eventId: res.data.data?.id, uuid: exhibitorId }).then((result) => {
                        setLoginDialog(true);
                        setGuestData(result.data.data);
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }).catch((err) => {
                console.log(err);
                navigate(`/${PAGE_PATHS.DASHBOARD}`);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                })
            })
        } else {
            getEventDetails({ eventId: commonEventId ?? id, commonEventId: commonEventId ?? null, userId: userData?.id ?? null, isExhibitor: window.location.href.includes('/e/exhibitor/') }).then((res) => {
                if (commonEventId && res.data?.data.is_private) {
                    if (isLoggedIn && res.data.data.guest_uuid) {
                        navigate(`/${PAGE_PATHS.GUEST_EVENT_DETAILS.replace(':guestId', res.data.data.guest_uuid)}`);
                    } else if (isLoggedIn && res.data.data.exhibitor_id) {
                        navigate(`/${PAGE_PATHS.EXHIBITOR_EVENT_DETAILS.replace(':exhibitorId', res.data.data.exhibitor_id)}`);
                    } else {
                        setLoginDialog(true);
                    }
                }
                dispatch(update_event_details(res.data.data))
            }).catch((err) => {
                console.log(err);
                navigate(`/${PAGE_PATHS.DASHBOARD}`);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                })
            })
        }
        return (() => {
            dispatch(clear_event_details())
        })
    }, [guestId]);

    const handleDialogClose = (receivedGuestId) => {
        if (guestId) {
            setLoginDialog(false);
            return;
        }
        else if (!receivedGuestId || typeof (receivedGuestId) === "object") {
            return;
        }
        setLoginDialog(false);
    }

    const refs = { // For redirecting to sections using quick actions
        rsvpBtnRef: useRef(null),
        settingsBtnRef: useRef(null),
        ticketsBtnRef: useRef(null),
        formBtnRef: useRef(null),
        paymentsBtnRef: useRef(null),
        checkInsBtnRef: useRef(null),
        guestsTabRef: useRef(null),
        invitationOptionsRef: useRef(null),
        pollsBtnRef: useRef(null),
    }

    useEffect(() => {
        if (eventData && isAllPermission) {
            dispatch(set_sidebar_shortcuts(sidebarShortcuts));
        }

        // Cleanup on dismount
        return () => {
            // dispatch(clear_event_details());
            dispatch(clear_sidebar_shortcuts()); // Clear the sidebar shortcuts
        };
    }, [eventData, dispatch]);

    const [isSharedLink, setIsSharedLink] = useState(false);
    useEffect(() => {
        // Check if the URL matches the shared link pattern
        const isFromSharedLink = window.location.pathname.includes('/e/');
        setIsSharedLink(isFromSharedLink);
    }, []);

    return (
        <React.Fragment>
            <EventDetailsContext.Provider value={{ refs, setTabValue, subSection }}>

                <EventDetailCards />
                {isAllPermission && subSection === 'event-overview' ?
                    <EventShortcuts /> :
                    <Divider orientation='horizontal' sx={{ mt: 3 }} />
                }
                {/* <Divider orientation='horizontal' sx={{ my: 3 }} /> */}

                <EventMenubar tabValue={tabValue} handleTabChange={handleTabChange} />

                {/* Here this tab is only there in eventData from backend by checking all conditions & shows conditionally */}
                {(registrationTab && !loginDialog) &&
                    ((tabValue !== registrationTabIndex) &&
                        (!eventData?.is_registered)
                    ) ?
                    <Stack alignItems='center' justifyContent='center' sx={{
                        width: '100%',
                        height: '6rem',
                        bottom: '0px',
                        zIndex: 10000, //To show it over every things in event details
                        position: 'sticky'
                    }}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            onClick={handleClick}
                            sx={{
                                bgcolor: "#1F274A",
                                color: "white",
                                borderRadius: '1.5rem',
                                px: '2rem',
                                ":hover": {
                                    bgcolor: "#1F274A",
                                    boxShadow: '0 0 5px 5px rgba(0, 0, 50, 0.4)',
                                },
                                boxShadow: '0 0 5px 5px rgba(0, 0, 50, 0.4)',
                                width: 'auto'
                            }}
                        >
                            Registration
                        </Button>
                    </Stack>
                    : null
                }
                {loginDialog ? <LoginDialog open={true}
                    handleClose={(receivedGuestId) => handleDialogClose(receivedGuestId)} guestData={guestData} /> : null}
            </EventDetailsContext.Provider>
            {isSharedLink && (
                <Box sx={{
                    width: '100%',
                    textAlign: 'center',
                    py: 2,
                    mt: 'auto', // Push to bottom
                    borderTop: '1px solid #E5E4E9',
                    backgroundColor: '#f8f9fa'
                }}>
                    <Typography
                        sx={{
                            fontSize: '0.9rem',
                            color: '#6F738B',
                            fontWeight: 500,
                            '& span': {
                                color: '#FF8359',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }
                        }}
                    >
                        Powered by{' '}
                        <span onClick={() => window.open('https://wowsly.com/', '_blank')}>
                            Wowsly
                        </span>
                    </Typography>
                </Box>
            )}
        </React.Fragment>
    )
};

export default EventDetails;
