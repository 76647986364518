import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Divider, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultEvent from '../../../../../../assets/defaultEvent.webp';
import { BACKEND_URL } from "../../../../../../utils/constants/apis.urls";
import { PAGE_PATHS } from '../../../../../../utils/constants/page-path.constants';
import { ALL_PERMISSION_ROLES } from "../../../../../../utils/constants/roles";
import { wrapString } from "../../../../../../utils/functions/wrapString";
import DeleteGroupEvent from "./DeleteGroupEvent";

const GroupEventCard = ({ setItems, groupEventDetails }) => {
    const navigate = useNavigate();
    let [deleteDialog, setDeleteDialog] = useState(false);
    const handleOpen = () => {
        setDeleteDialog(true);
    }
    const handleClose = () => {
        setDeleteDialog(false);
    }
    const handleOpenDetails = () => {
        if (groupEventDetails?.guest_uuid) {
            navigate(`/${PAGE_PATHS.GUEST_EVENT_DETAILS.replace(':guestId', groupEventDetails.guest_uuid)}`);
        } else {
            navigate(`/${PAGE_PATHS.EVENT_DETAILS.replace(':id', groupEventDetails?.id).replace(':subSection', 'event-reports')}`);
        }
    }

    return (
        <>
            <Stack sx={{
                width: '15rem',
                height: ALL_PERMISSION_ROLES.includes(groupEventDetails?.current_user_role) ? '16rem' : '14rem',
                borderRadius: '1rem',
                m: '8px 1.5rem 8px 8px',
                boxShadow: '0px 0px 9px #ccc',
                userSelect: "none",
                transition: 'transform 0.3s',
                ":hover": {
                    transform: 'scale(1.04)',
                }
            }}>
                <Stack sx={{
                    height: ALL_PERMISSION_ROLES.includes(groupEventDetails?.current_user_role) ? '13rem' : '100%',
                    ':hover': {
                        cursor: 'pointer',
                    }
                }}
                    onClick={handleOpenDetails}
                >
                    <Stack>
                        {groupEventDetails ?
                            <>
                                {groupEventDetails.event_main_photo ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            width: '100%',
                                            height: '8.8rem',
                                            objectFit: 'cover',
                                            borderTopLeftRadius: '0.9rem',
                                            borderTopRightRadius: '0.9rem',
                                        }}
                                        src={`${groupEventDetails?.event_main_photo}`}
                                        alt="Grp."
                                    />
                                ) : (
                                    <Stack justifyContent='center' alignItems='center' sx={{
                                        height: '8.8rem',
                                    }}>
                                        <Box
                                            component="img"
                                            sx={{
                                                width: '4rem',
                                                height: '2.5rem'
                                            }}
                                            src={defaultEvent}
                                            alt="Grp."
                                        />
                                    </Stack>
                                )}
                            </> :
                            <Skeleton animation='wave' width='100%' height='9rem'
                                sx={{ transform: 'scale(1,1)', borderTopLeftRadius: '0.5rem !important', borderTopRightRadius: '0.5rem !important' }}
                            />
                        }
                    </Stack>
                    <Stack spacing={0.5} sx={{ flex: 1, px: 1.5 }} justifyContent='center'>
                        {groupEventDetails ?
                            <Typography sx={{ color: '#1F274A', fontWeight: 'bold' }}>{wrapString(groupEventDetails.title, 20)}</Typography>
                            : <Skeleton animation='wave' width='50%' height='1rem'
                                sx={{ transform: 'scale(1,1)' }} />
                        }
                        {groupEventDetails ?
                            <Typography sx={{ color: '#6F738B', fontSize: '13px' }}>
                                {groupEventDetails?.start_date ?
                                    <>
                                        {moment(groupEventDetails?.start_date).format('ddd, Do MMM YYYY')},&nbsp;
                                        {moment(groupEventDetails?.start_time, "HH:mm:ss").format('h:mm a')}
                                    </> :
                                    <>{groupEventDetails?.schedule_announcement_description}</>
                                }
                            </Typography>
                            : <Skeleton animation='wave' width='100%' height='1.1rem'
                                sx={{ transform: 'scale(1,1)' }} />
                        }
                    </Stack>
                </Stack>
                {(groupEventDetails && ALL_PERMISSION_ROLES.includes(groupEventDetails?.current_user_role)) ?
                    <>
                        <Divider />
                        <Stack direction='row' justifyContent='center' alignItems='center' spacing={0.5}
                            sx={{ flexGrow: 1 }}>
                            <IconButton onClick={() => handleOpen('delete')}><DeleteIcon sx={{ color: '#1F274A' }} /></IconButton>
                        </Stack>
                        {
                            deleteDialog ? <DeleteGroupEvent setItems={setItems} groupEventDetails={groupEventDetails}
                                handleClose={handleClose} /> : null
                        }
                    </>
                    : null
                }
            </Stack >
        </>
    )
};

export default GroupEventCard;
