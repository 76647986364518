import { Button, Dialog, DialogTitle, List, ListItemButton, IconButton, DialogContent, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TicketsBookedDialog from "./TicketsBookedDialog";

export const EventReports = ({ cardData, refs }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleListItemClick = (title) => {
        if (title === 'Tickets Booked') {
            setDialogContent('ticketsBooked');
            setDialogOpen(true);

        } else if (title === 'Event Check-ins') {
            refs.settingsBtnRef.current?.click()
            setTimeout(() => refs.checkInsBtnRef.current?.click(), 500)
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    return (
        <Box>
            {/* Refresh Button (commented out in your original code) */}
            {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <Button variant="inherit"
                    sx={{ color: 'gray', borderRadius: '50%', height: '3.25rem', width: '3.25rem', p: '.05rem', aspectRatio: 1 }}
                    onClick={() => { }}
                >
                    <RefreshIcon />
                </Button>
            </Box> */}

            {/* Dialog for Tickets Booked */}
            {dialogContent === 'ticketsBooked' &&
                <Dialog
                    fullWidth
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    scroll="paper"
                    PaperProps={{
                        sx: {
                            width: { xs: '90%', md: '60%' },
                            maxWidth: { lg: '700px' }, m: { xs: 0 }
                        }
                    }}
                    sx={{
                        height: '30rem',
                    }}
                >
                    <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                        <IconButton onClick={handleDialogClose} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                            <CloseIcon fontSize='medium' />
                        </IconButton>
                        <Stack direction='row' alignItems='center' sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}>
                                Tickets Booked
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    <TicketsBookedDialog></TicketsBookedDialog>
                </Dialog>
            }

            {/* List of Items */}
            <List sx={{
                display: 'flex',
                flexWrap: 'wrap',
                px: '2rem',
                justifyContent: 'space-evenly',
                gap: '2rem',
            }}>
                {cardData.map((card, index) => (
                    <ListItemButton
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '30%',
                            minHeight: 150,
                            minWidth: 150,
                            backgroundColor: '#E5E4E9',
                            p: '.5rem',
                            px: '1rem',
                            borderRadius: '.5rem',
                            boxShadow: '2px 2px 5px rgba(0,0,0,0.3)',
                            transition: '250ms linear scale',
                            cursor: 'pointer',
                            '&:hover': {
                                scale: '1.05',
                                backgroundColor: '#E5E4E9',
                            }
                        }}
                        onClick={() => handleListItemClick(card.title)} // Handle click
                    >
                        <DataCard CardData={card} refs={refs} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

const DataCard = ({ CardData, refs }) => {
    return (
        <Stack
            direction="column"
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', mb: '1rem' }}>
                {CardData.title}
            </Typography>

            <Box sx={{ fontSize: '1rem', display: 'flex', flexDirection: 'column', mb: 'auto' }}>
                {Object.keys(CardData.body).map((key, index) => {
                    const value = CardData.body[key];
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                            <Typography sx={{ display: 'inline', fontWeight: '500' }}>
                                {key}
                            </Typography>
                            <Typography sx={{ display: 'inline', marginLeft: '4px' }}>
                                {`: ${value}`}
                            </Typography>
                        </div>
                    );
                })}
            </Box>
        </Stack>
    );
};
