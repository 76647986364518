import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/WowslyMobileLogo2.png';
import { DrawerTabs } from '../utils/constants/drawer.tabs';
import { sidebarTabs } from '../utils/constants/sidebar.tabs';
import { useSelector } from 'react-redux';
import { sidebarShortcutsTabs } from '../redux/slices/sidebarDataSlice';
import { eventDetails } from '../redux/slices/eventDataSlice';
import { PAGE_PATHS } from '../utils/constants/page-path.constants';

const DashboardDrawer = ({ handleMobileDrawerToggle, handleWebDrawerToggle }) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const isSmallDevices = useMediaQuery("(max-width:900px)");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const eventData = useSelector(eventDetails);
    const sidebarShortcuts = useSelector(sidebarShortcutsTabs);

    const navigate = useNavigate();

    useEffect(() => {
        const index = sidebarTabs.indexOf(pathname);
        setSelectedIndex(index >= 0 ? index : 0);  // Default to first tab if no match
    }, [pathname]);

    const handleClick = (item) => {
        navigate(`/${item.url}`);
        if (isSmallDevices) {
            handleMobileDrawerToggle(); // Close mobile drawer on click
        }
    };

    // Memoize the sidebarShortcuts to prevent unnecessary re-renders
    const sidebarShortcutsMemo = useMemo(() => sidebarShortcuts, [sidebarShortcuts]);

    // Create an array to track the open state of each sidebarShortcut
    const [openItems, setOpenItems] = useState(() => Array(sidebarShortcutsMemo.length).fill(false));

    useEffect(() => {
        sidebarShortcutsMemo.forEach((_, index) => {
            const timer = setTimeout(() => {
                setOpenItems(prevState => {
                    const updatedState = [...prevState];
                    updatedState[index] = true; // Set the specific item to open
                    return updatedState;
                });
            }, 100 * index); // Stagger the opening with a delay based on the index
            return () => clearTimeout(timer);
        });
    }, [sidebarShortcutsMemo]);

    return (
        <>
            <Box sx={{ mt: '0.75rem', }}>
                <IconButton
                    size='small'
                    onClick={isSmallDevices ? handleMobileDrawerToggle : handleWebDrawerToggle}
                    sx={{
                        float: 'right',
                        mr: 1,
                        boxShadow: '0 0 10px 1px rgb(115 103 240 / 70%)'
                    }}
                >
                    {theme.direction === 'ltr' && <MenuOpenIcon sx={{ color: 'white' }} />}
                </IconButton>
            </Box>
            <Box onClick={isSmallDevices ? handleMobileDrawerToggle : null} sx={{ textAlign: 'center', flex: 1 }}>
                <Toolbar sx={{ justifyContent: 'center', width: '100%' }}>
                    <Box component='img'
                        sx={{
                            width: '7rem',
                            height: '7rem',
                        }}
                        alt="Wowsly Logo"
                        src={logo}
                    />
                </Toolbar>
                <List sx={{
                    mt: 2,
                    mx: 1,
                    '&& .Mui-selected': {
                        borderRadius: '4px',
                        p: '0.3rem 0rem',
                        overflow: 'auto',
                        background: 'linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7))',
                        boxShadow: '0 0 10px 1px rgb(115 103 240 / 70%)'
                    }
                }}>
                    {DrawerTabs.map((item, index) => (
                        <ListItem
                            key={index}
                            sx={{ height: 'auto', display: 'flex', flexDirection: 'column', px: '.75rem' }}
                            disablePadding
                        >
                            <ListItemButton
                                sx={{ textAlign: 'left', alignSelf: 'flex-start', width: '100%' }}
                                onClick={() => handleClick(item)}
                                selected={selectedIndex === index}
                            >
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='center'
                                    sx={{
                                        p: (selectedIndex === index || (selectedIndex === -1 && index === 0)) ? 1 : 0,
                                        ml: (selectedIndex === index || (selectedIndex === -1 && index === 0)) ? 1 : 0
                                    }}>
                                    {item.icon}
                                    <ListItemText sx={{ ml: 1.5 }} primary={item.name} />
                                </Stack>
                            </ListItemButton>
                            {item.id === DrawerTabs[0]?.id && sidebarShortcutsMemo?.length > 0 && (
                                sidebarShortcutsMemo.map((tab, tabIndex) => {
                                    const TabText = Object.keys(tab)[0];
                                    const TabRoute = Object.values(tab)[0];
                                    const CurrentRoute = window.location.pathname.split('/').reverse()[0];

                                    return (
                                        <Collapse key={tabIndex} in={openItems[tabIndex]} timeout={500}
                                            sx={{
                                                width: '90%',
                                            }}>
                                            <ListItemButton
                                                sx={{
                                                    alignSelf: 'flex-start',
                                                    borderRadius: '.25rem',
                                                    p: '.25rem',
                                                    mt: '.5rem',
                                                    ml: '.75rem',
                                                    width: '100%',
                                                    px: '.5rem',
                                                    background: TabRoute === CurrentRoute ? 'linear-gradient(118deg, #7367F0, rgba(115, 103, 240, 0.7))' : 'transparent'
                                                }}
                                                onClick={() => navigate(PAGE_PATHS.EVENT_DETAILS.replace(":id", eventData?.id).replace(':subSection', TabRoute))}
                                            >
                                                <ListItemText sx={{ fontSize: '.75rem' }} primary={`${TabText} >`} />
                                            </ListItemButton>
                                        </Collapse>
                                    );
                                })
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );
};

export default DashboardDrawer;
