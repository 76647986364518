import { Box, DialogContent, List, ListItem, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import NoRepliesGif from "../../../../../../assets/no-replies.gif";
import { useSelector } from "react-redux";
import { getEventTickets } from "../../../../../../services/wEvents/eventDetails";
import { getCheckedInTickets } from "../../../../../../services/wEvents/eventGuests";
import { eventDetails } from "../../../../../../redux/slices/eventDataSlice";

const TicketsBookedDialog = () => {
    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const [soldTicketData, setSoldTicketData] = useState(null);
    const eventData = useSelector(eventDetails);

    useEffect(() => {
        const fetchCheckedInTickets = async () => {
            try {
                const eventRes = await getEventTickets({ eventId: eventData?.id }); 
                setSoldTicketData(eventRes.data.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchCheckedInTickets();
    }, [eventData?.id]);



    return (
        <DialogContent dividers sx={{
            p: { xs: '0rem', md: '0rem' },
            height: { xs: '26.5rem', md: '31.5rem' },
        }}>
            {soldTicketData ?
                soldTicketData.length === 0 ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt: 5 }}>
                        <Box
                            component="img"
                            src={NoRepliesGif}
                            width={isSmallDevice ? "90%" : "60% !important"}
                            height={isSmallDevice ? "15rem" : "17rem"}
                        />
                    </Stack>
                ) : (
                    <Box sx={{ pl: 1, px: 1, my: 0.5 }}>
                        <List sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-evenly',
                            gap: '1.5rem',
                        }}>
                            {soldTicketData?.map((ticket, index) => (
                                <ListItem key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '40%',
                                        minWidth: 150,
                                        minHeight: 150,
                                        backgroundColor: '#E5E4E9',
                                        p: '.5rem',
                                        px: '1rem',
                                        borderRadius: '.5rem',
                                        boxShadow: '2px 2px 5px rgba(0,0,0,0.3)',
                                        transition: '250ms linear scale',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            scale: '1.05',
                                            backgroundColor: '#E5E4E9',
                                        }
                                    }}
                                >
                                    <Stack
                                        direction='column'
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', mb: '1rem' }}>
                                            {ticket.title}
                                        </Typography> 
                                        {ticket &&  
                                            <Box sx={{ fontSize: '1rem', display: 'flex', flexDirection: 'column', mb: 'auto' }}>
                                                <Typography align="left">Sold : {ticket?.sold_out}</Typography>
                                                {ticket?.facilities.map((facility, idx) => (
                                                    <Typography key={idx} align="left">
                                                        {facility.name} : {facility.scan_quantity}
                                                    </Typography>
                                                ))}
                                            </Box>}
                                    </Stack>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )
                :
                <Stack flexDirection='row' gap='1.5rem' flexWrap='wrap'
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        mt: '10%'
                    }}
                >
                    {[1, 2].map((_, index) => (
                        <Skeleton
                            key={index}
                            sx={{
                                mx: '0.5rem !important',
                                p: '0rem !important',
                                transform: 'scale(1,2)',
                            }}
                            width='40%'
                            height='5rem'
                            animation="wave"
                        />))
                    }
                </Stack>
            }
        </DialogContent>
    );
};

export default TicketsBookedDialog;
