import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import Webcam from 'react-webcam'; // Import react-webcam
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning'; // For showing warning
import { getBusinessCardDetails } from '../../../../../../../../services/wEvents/eventExhibitor'; // Import API call

export default function BusinessCardReader({ onClose, setFormValuesFromScan }) {
    const [loading, setLoading] = useState(false); // Loading state
    const [webcamInitializing, setWebcamInitializing] = useState(true); // Track if webcam is still initializing
    const [webcamReady, setWebcamReady] = useState(false); // State to track webcam readiness

    const webcamRef = React.useRef(null);

    // Callback for when the webcam is ready
    const onUserMedia = () => {
        setWebcamReady(true);
        setWebcamInitializing(false); // Webcam has finished initializing
    };

    // Capture image from webcam and immediately send it to scan
    const captureImageAndSend = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            setLoading(true); // Show loading indicator when the image is being processed
            try {
                const response = await fetch(imageSrc);
                const blob = await response.blob();

                const formData = new FormData();
                formData.append('business_card', blob, 'business_card.jpg');
                const { name, email, phone, otherContactDetail, companyName, address, designation } = (await getBusinessCardDetails(formData)).data;
                setFormValuesFromScan(name, email, phone, otherContactDetail, companyName, address, designation);  // Send the processed data to parent
                onClose()
            } catch (error) {
                console.error('Error uploading business card:', error);
            } finally {
                setLoading(false); // Hide loading indicator once processing is complete
            }
        }
    };

    return (
        <Box
            sx={{
                textAlign: "center",
                padding: 4,
                maxWidth: 600,
                margin: "0 auto",
                position: 'relative',
                border: "1px solid #ddd",
                boxShadow: 2,
                transition: 'opacity 0.5s ease-in-out',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Business Card Reader
            </Typography>
            <Button onClick={onClose} sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                transition: 'transform 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.1)'
                }
            }}
                color='warning'
            >
                <CloseIcon fontSize='medium' />
            </Button>

            {/* Show warning if the webcam is still initializing */}
            {webcamInitializing && (
                <Box sx={{ marginBottom: 2 }}>
                    <WarningIcon color='warning' sx={{ fontSize: 40 }} />
                    <Typography variant="h6" color="warning">
                        Webcam is initializing...
                    </Typography>
                </Box>
            )}

            <Box sx={{ marginBottom: 2 }}>
                {/* Webcam Component */}
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{
                        facingMode: "environment",
                    }}
                    onUserMedia={onUserMedia}
                    style={{
                        width: "100%",
                        height: 350, // Fixed height for consistency
                        borderRadius: 8,
                        border: "1px solid #ddd",
                    }}
                />
            </Box>

            {/* Capture Button */}
            {webcamReady && (
                <Button
                    variant="contained"
                    color="warning"
                    onClick={captureImageAndSend} // Call the new function to capture and send image immediately
                    disabled={loading}
                    sx={{
                        marginBottom: 2,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    {loading ? (
                        <CircularProgress color="inherit" size={24} />
                    ) : (
                        <PanoramaFishEyeIcon sx={{ fontSize: 40 }} />
                    )}
                </Button>
            )}

            {/* Loader for processing the OCR and extracting details */}
            {loading && (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <CircularProgress size={60} color="warning" />
                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                        Extracting details...
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
