import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Divider, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EventDetailsContext } from '../..';
import LoginDialog from '../../../../components/LoginDialog';
import { eventDetails } from "../../../../redux/slices/eventDataSlice";
import { userStatus } from "../../../../redux/slices/userDataSlice";
import { downloadEventQRForRegistrationSelfCheckIn, getEventForms } from '../../../../services/wEvents/eventDetails';
import { getGuestDetails } from '../../../../services/wEvents/eventGuests';
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";
import { generateAndCopyCommonEventLink } from '../../../../utils/functions/generateCommonEventLink';
import EditEventDialog from "./components/EditEvent";
import EventDatesDialog from './components/EventDatesCalender';
import EventSettingsDialog from "./components/EventSettings";
import EventQrCodeDialog from './components/EventQrcode';
import { BACKEND_URL } from '../../../../utils/constants/apis.urls';
import { ForceMetaUpdate } from '../../../../utils/ForceMetaUpdate/ForceMetaUpdate';



const EventBasicDetails = () => {
    const { id, guestId, commonEventTitle } = useParams();
    const isLoggedIn = useSelector(userStatus);
    const eventData = useSelector(eventDetails);
    const { refs } = useContext(EventDetailsContext);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);
    const scanPermission = eventData?.current_user_role === 'manager';
    const isSelfCheckIn = eventData?.is_self_check_in;
    const [loginDialog, setLoginDialog] = useState(false);
    const [guestData, setGuestData] = useState(null);
    const handleDialogClose = () => {
        setLoginDialog(false);
    }

    const eventMainData = [...(
        (eventData?.is_online ? eventData?.event_link
            : (!eventData?.multiple_venues && eventData?.address)
        ) ? [
            {
                key: 'venue',
                icon: eventData?.is_online ? <BookOnlineIcon /> : <LocationOnIcon />,
                title: eventData?.is_online ? 'Zoom Event' : eventData?.address,
                desc: eventData?.is_online ? 'Online Event' :
                    (eventData.city && eventData.state)
                        ? `${eventData.city}, ${eventData.state}`
                        : eventData.city
                            ? eventData.city
                            : eventData.state
                                ? eventData.state
                                : "",
                button_text: eventData?.is_online ? 'Join Link' : 'Get Directions',
                redirect_url: eventData?.is_online ? eventData?.event_link : eventData?.google_map_link ?? null,
            }
        ] : []
    ),
    {
        key: 'datetime',
        icon: <CalendarMonthIcon />,
        title: eventData?.schedule_announcement_description ? eventData?.schedule_announcement_description : eventData?.end_date === null || (eventData?.start_date === eventData?.end_date) ?
            moment(eventData?.start_date).format("ddd, Do MMM YYYY") :
            `${moment(eventData?.start_date).format("Do MMM")} - ${moment(eventData?.end_date).format("Do MMM")}`,
        desc: eventData?.schedule_announcement_description ? '' : eventData?.end_time === null ? moment(eventData?.start_time, 'HH:mm:ss').format("h:mm A") : `${moment(eventData?.start_time, 'HH:mm:ss').format("h:mm A")} - ${moment(eventData?.end_time, 'HH:mm:ss').format("h:mm A")}`,
        button_text: 'Add To Calender',
        redirect_url: null
    },
    {
        key: 'verification',
        icon: isSelfCheckIn ? (isAllPermission ? <QrCode2Icon sx={{ fontSize: '35px' }} /> : <QrCodeScannerIcon sx={{ fontSize: '35px' }} />) : ((isAllPermission || scanPermission) ? <QrCodeScannerIcon sx={{ fontSize: '35px' }} /> : <QrCode2Icon sx={{ fontSize: '35px' }} />),
        // title: (isAllPermission || scanPermission) ? 'Scan QR Code' : 'Show QR Code',
        title: 'For Check-in',
        desc: isSelfCheckIn ? (isAllPermission ? 'Show QR Code' : 'Scan QR Code') : ((isAllPermission || scanPermission) ? 'Scan QR Code' : 'Show QR Code'),
        button_text: 'Show QR code',
        redirect_url: null
    }];

    let [dialog, setDialog] = useState({
        edit: false,
        settings: false,
        calender: false,
        qrCode: false
    });
    const handleOpen = (operation) => {
        document.body.classList.add("no-scroll");
        setDialog({ ...dialog, [operation]: true });
    }
    const handleClose = (event, reason, operation) => {
        if (reason && reason === "backdropClick") // For not closing dialog on clicking outside of dialog
            return;
        document.body.classList.remove("no-scroll");
        setDialog({ ...dialog, [operation]: false });
    }

    const handleIconClick = (item) => {
        if (item.key === 'datetime' && eventData?.schedule_announcement_description) {
            // Do nothing
        } else if (item.key === 'venue' && item.redirect_url) {
            window.open(item.redirect_url, '_blank');
        } else if (item.key === 'datetime' && !eventData?.schedule_announcement_description) {
            setDialog({ ...dialog, calender: true });
        } else if (item.key === 'verification') {
            if (!isLoggedIn && !commonEventTitle && !eventData.has_registration) {
                getGuestDetails({ eventId: eventData?.id, uuid: eventData?.guest_uuid }).then((result) => {
                    setLoginDialog(true);
                    setGuestData(result.data.data);
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                setDialog({ ...dialog, qrCode: true });
            }
        }
    }

    const downloadQRForRegistrationAndCheckIn = () => {
        downloadEventQRForRegistrationSelfCheckIn({ eventId: eventData?.id }).then(res => {
            console.log("res", res);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${eventData?.title.replaceAll(" ", "_").toLowerCase()}_qr_code.pdf`);
            document.body.appendChild(link);
            link.click();

            toast.success("Event QR generated successfully", {
                position: "top-right",
                theme: "dark"
            });
        }).catch(err => {
            console.log("err", err);
        })
    }

    const [registrationStatus, setRegistrationStatus] = useState(null);

    useEffect(() => {
        if (id || scanPermission) {
            setRegistrationStatus(true);
        } else if ((commonEventTitle && (Object.keys(eventData).length > 0))) {
            setRegistrationStatus(false); // Will always remain false
        }
        else if (guestId && (Object.keys(eventData).length > 0)) {
            // This API will fetch details about event user registration status along with form and 
            // ticket payment, and based on that gives 0 or 1 for qr code visibility
            getEventForms({ eventId: eventData?.id, guest_uuid: !isLoggedIn ? guestId : null }).then((res) => {
                setRegistrationStatus(res.data.is_filled);
            }).catch((err) => {
                console.log(err);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData]);

    const getMetaDescription = React.useMemo(() => {
        if (!eventData?.title) return '';
        return `${eventData.title} - ${eventData.category || ''} ${eventData.schedule_announcement_description ||
            (eventData.start_date ? moment(eventData.start_date).format("ddd, Do MMM YYYY") : '')
            }`.trim();
    }, [eventData]);

    // Get event image URL
    const getEventImage = React.useMemo(() => {
        if (eventData?.event_main_photo) {
            return eventData.event_main_photo.startsWith('http')
                ? eventData.event_main_photo
                : `${BACKEND_URL}${eventData.event_main_photo}`;
        }
        return null;
    }, [eventData]);

    // Force update meta tags when event data changes
    useEffect(() => {
        if (eventData?.title) {
            ForceMetaUpdate(
                eventData.title,
                getMetaDescription,
                getEventImage
            );
        }
    }, [eventData, getMetaDescription, getEventImage]);

    return (
        <>
            <Box sx={{
                width: {
                    xs: '95%',
                    sm: '38rem',
                    lg: isLoggedIn ? '38rem' : '44rem'
                },
                height: { sm: 'max-content' },
                m: '1rem'
            }}>
                <Box sx={{
                    border: { xs: '1px solid #E5E4E9' },
                    borderTopLeftRadius: { xs: '15px' },
                    borderTopRightRadius: { xs: '15px' },
                }}>
                    <Box sx={{ p: '1rem' }}>
                        <Stack direction='row' justifyContent='space-between' sx={{ display: 'flex', flexWrap: 'wrap' }} spacing={3}>
                            <Box sx={{ minWidth: '50%', flex: 1 }}>
                                {(Object.keys(eventData).length > 0) ?
                                    <Typography sx={{
                                        fontSize: '1.8rem',
                                        fontWeight: 500,
                                        wordBreak: 'break-word'
                                    }}>
                                        {eventData?.title}
                                    </Typography>
                                    : <Skeleton height='2rem' width='90%' />
                                }
                                {Object.keys(eventData).length > 0 ?
                                    <Typography sx={{ flex: 1, fontSize: '0.8rem', color: '#6F738B', fontWeight: 400 }}>
                                        {eventData?.category}
                                    </Typography>
                                    : <Skeleton width='60%' height='1.5rem' />
                                }
                            </Box>

                            {isAllPermission ?
                                <>
                                    {Object.keys(eventData).length > 0 ?
                                        <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: 'space-evenly' }}>
                                            <IconButton onClick={() => generateAndCopyCommonEventLink(id, eventData)}
                                                sx={{ ":hover": { backgroundColor: 'white' } }}>
                                                <InsertLinkIcon />
                                            </IconButton>
                                            {eventData.is_self_check_in ?
                                                <IconButton onClick={downloadQRForRegistrationAndCheckIn}
                                                    sx={{ ":hover": { backgroundColor: 'white' } }}>
                                                    <QrCode2Icon />
                                                </IconButton>
                                                : null
                                            }
                                            <IconButton onClick={() => handleOpen('edit')}
                                                sx={{ ":hover": { backgroundColor: 'white' } }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                ref={refs.settingsBtnRef}
                                                onClick={() => handleOpen('settings')} sx={{ ":hover": { backgroundColor: 'white' } }}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </Stack> :
                                        <Skeleton width='6rem' />
                                    }
                                </> : null
                            }
                        </Stack>

                        <Divider sx={{ my: 2 }} orientation='horizontal' />

                        <Stack spacing='2rem' sx={{ pl: { sm: '1rem' } }}>
                            {(
                                eventMainData.slice(0, -1))?.map((item, i) => ( // Doing this because don't want to show QR code option to guest if not registration done
                                    <Stack key={i} direction='row' spacing='1.5rem' alignItems='center'>
                                        <Box sx={{ flexGrow: 1 }}>
                                            {(Object.keys(eventData).length > 0 && registrationStatus !== null) ?
                                                <Typography sx={{ fontSize: '1rem', fontWeight: 400, color: '#1F274A' }}>{item.title}</Typography>
                                                : <Skeleton width='80%' height='1.5rem' />
                                            }
                                            {(Object.keys(eventData).length > 0 && registrationStatus !== null) ?
                                                <Typography sx={{ fontSize: '0.8rem', color: '#6F738B' }}>{item.desc}</Typography>
                                                : <Skeleton width='50%' height='1rem' />
                                            }
                                        </Box>
                                        {(Object.keys(eventData).length > 0 && registrationStatus !== null) ?
                                            <IconButton
                                                size='small'
                                                color="warning"
                                                onClick={() => handleIconClick(item)}
                                                target='_blank'
                                                sx={{
                                                    border: '1px solid #FF8359', borderRadius: '4px',
                                                    backgroundColor: 'rgba(255, 131, 89, 0.16)',
                                                    ":hover": {
                                                        backgroundColor: 'rgba(255, 131, 89, 0.16)'
                                                    },
                                                    cursor: item.clickable ? 'pointer' : 'default',
                                                }}
                                            >
                                                {item.icon}
                                            </IconButton>
                                            : <Skeleton variant="circular" width='2.5rem' height='2.5rem' />
                                        }
                                        {loginDialog ? <LoginDialog open={true}
                                            handleClose={(shouldDialogClose) => handleDialogClose(shouldDialogClose, 'login')} guestData={guestData} /> : null}
                                    </Stack >
                                ))}
                        </Stack >
                    </Box >
                </Box >

                {
                    ((!eventData.is_self_check_in && (isLoggedIn && registrationStatus)) ||
                        ((eventData.is_self_check_in) &&
                            ((isLoggedIn && registrationStatus) || (!eventData.has_registration && eventData.is_private)))) ?
                        <>
                            {(eventMainData.slice(-1))?.map((item, i) => (
                                <Box key={i} sx={{
                                    border: { xs: '1px solid #E5E4E9' },
                                    borderRadius: { xs: '8px' },
                                    backgroundColor: '#1F274A',
                                    mt: '1rem',
                                    cursor: 'pointer',
                                    ":hover": {
                                        backgroundColor: '#21375f',
                                    }
                                }}
                                    onClick={() => handleIconClick(item)}>
                                    <Box sx={{ p: '1rem' }}>
                                        <Stack spacing='2rem' sx={{ pl: { sm: '0.5rem' } }}>
                                            <Stack key={i} direction='row' spacing='1.5rem' alignItems='center'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    {(Object.keys(eventData).length > 0 && registrationStatus !== null) ?
                                                        <Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: '#FFFFFF' }}>{item.title}</Typography>
                                                        : <Skeleton width='80%' height='1.5rem' />
                                                    }
                                                    {(Object.keys(eventData).length > 0 && registrationStatus !== null) ?
                                                        <Typography sx={{ fontSize: '1.1rem', color: '#FFFFFF' }}>{item.desc}</Typography>
                                                        : <Skeleton width='50%' height='1rem' />
                                                    }
                                                </Box>
                                                {(Object.keys(eventData).length > 0 && registrationStatus !== null) ?
                                                    <IconButton
                                                        size='small'

                                                        target='_blank'
                                                        sx={{
                                                            color: 'white',
                                                            border: '2px solid #FFFFFF',
                                                            borderRadius: '0px',
                                                            padding: '1px',
                                                            fontSize: '44px',
                                                        }}>
                                                        {item.icon}
                                                    </IconButton>
                                                    : <Skeleton variant="circular" width='2.5rem' height='2.5rem' />
                                                }
                                                {loginDialog ? <LoginDialog open={true}
                                                    handleClose={() => handleDialogClose('login')} guestData={guestData} /> : null}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Box>
                            ))}
                        </> :
                        <></>
                }
            </Box >

            {
                dialog.edit ?
                    <EditEventDialog
                        handleClose={handleClose}
                        eventDetailsObj={eventData} />
                    : null
            }
            {
                dialog.settings ?
                    <EventSettingsDialog
                        handleClose={handleClose}
                        eventDetailsObj={eventData} />
                    : null
            }
            {
                dialog.calender ?
                    <EventDatesDialog
                        eventDetailsObj={eventData}
                        handleClose={handleClose} />
                    : null
            }
            {
                dialog.qrCode ?
                    <EventQrCodeDialog
                        eventDetailsObj={eventData}
                        handleClose={handleClose}
                        scanPermission={scanPermission}
                    />
                    : null
            }
        </>
    )
};

export default EventBasicDetails;
