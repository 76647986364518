import { axiosInstance } from "../../config/interceptor";
import { MESSAGE_TEMPLATE_API } from "../../utils/constants/apis.urls";
import { SEND_TICKET_EXISTINGUSER_API } from "../../utils/constants/apis.urls";

export const getInvitationMessageTemplates = (eventId) => {
    return axiosInstance.get(`${MESSAGE_TEMPLATE_API}?event_id=${eventId}`);
}
export const sendTicket = (payload) => {
    // Integration of API
    return axiosInstance.post(`${SEND_TICKET_EXISTINGUSER_API}`, payload);
}