import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Editor } from '@tinymce/tinymce-react';
import _ from "lodash";
import React from "react";
import CustomTooltip from "../../../../../../../../components/CustomTooltip";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { parseDecimalNumber } from '../../../../../../../../utils/functions/getFormattedNumber';


const SubscriptionDetailsDialog = ({ subscription, setModal }) => {
    const handleClose = () => {
        setModal(false);
    }
    const subscriptionCurrencySymbol = ticketCurrencies.find((t) => t.value === subscription.currency)?.symbol;

    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem' } }}>
                        Subscription Details
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={1} sx={{ width: '100%' }}>
                        <Box>
                            <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Name:</Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem', ml: 1, display: 'inline-block' }}>{subscription.title}</Typography>
                        </Box>
                        {subscription.description ?
                            <Box>
                                <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Description:</Typography>
                                <Typography sx={{ color: '#6F738B', fontSize: '.9rem', ml: 1, display: 'inline-block' }}>{subscription.description}</Typography>
                            </Box>
                            : null
                        }
                        <Box>
                            <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Validity:</Typography>
                            <Typography sx={{ color: '#6F738B', fontSize: '.9rem', ml: 1, display: 'inline-block' }}>
                                {subscription.validity_type !== 'lifetime' ? subscription.validity_time : null} {_.capitalize(subscription.validity_type)}
                            </Typography>
                        </Box>
                        {subscription.terms_and_conditions ?
                            <>
                                <Divider sx={{ py: 0.5 }} />
                                <Box sx={{ pt: '0.5rem' }}>
                                    <Typography sx={{ fontSize: '15px' }}>Terms & Conditions</Typography>
                                    <Editor
                                        apiKey="dsiirqxfbbshbtto49hmn3aplhh22hnefwjei883n0nyg0no"
                                        initialValue={subscription.terms_and_conditions}
                                        disabled={true}
                                        init={{
                                            height: '10rem',
                                            readonly: 1,
                                            menubar: false,
                                            toolbar: false,
                                        }}
                                    />
                                </Box>
                            </>
                            : null
                        }
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Stack direction='row' spacing={1}>
                        <Typography sx={{ color: '#1F274A', fontWeight: 'bold', fontSize: '1.2rem', display: 'inline-block' }}>Price:</Typography>
                        <Typography sx={{ color: '#1F274A', fontSize: '1.2rem', ml: 1, fontWeight: 'bold', display: 'inline-block' }}>
                            {Number(subscription.purchase_price)} <span dangerouslySetInnerHTML={{ __html: subscriptionCurrencySymbol }} />
                        </Typography>

                        <CustomTooltip
                            content={
                                <Stack spacing={1} sx={{ p: 0.5 }}>
                                    <Typography sx={{ fontSize: '0.75rem' }}>
                                        Actual Amount = {subscription.extra_charges_from_buyer ?
                                            parseDecimalNumber(subscription.amount + subscription.system_charges + subscription.razor_pay_fee, 2) :
                                            parseDecimalNumber(subscription.amount, 2)
                                        } <span dangerouslySetInnerHTML={{ __html: subscriptionCurrencySymbol }} />
                                    </Typography>
                                    <Typography sx={{ fontSize: '0.75rem' }}>
                                        Other Taxes = {
                                            subscription.extra_charges_from_buyer ?
                                                parseDecimalNumber(subscription.gst_on_subscription_price + subscription.wowsly_gst, 2) :
                                                parseDecimalNumber(subscription.gst_on_subscription_price, 2)
                                        } <span dangerouslySetInnerHTML={{ __html: subscriptionCurrencySymbol }} /></Typography>
                                    <Divider sx={{ backgroundColor: 'white !important' }} />
                                    <Typography sx={{ fontSize: '0.75rem' }}>Amount to Pay = {parseDecimalNumber(subscription.purchase_price, 2)} <span dangerouslySetInnerHTML={{ __html: subscriptionCurrencySymbol }} /></Typography>
                                </Stack>
                            }
                        />

                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default SubscriptionDetailsDialog;
