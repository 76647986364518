import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Avatar, Box, Button, Collapse, DialogActions, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InvitedUserMessageStatus from "../../../../../../../../../../components/InvitedUserMessageStatus";
import InvitedUserPagination from "../../../../../../../../../../components/InvitedUserPagination";
import MuiTabPanel from "../../../../../../../../../../components/muiTabPanel";
import { groupDetails } from "../../../../../../../../../../redux/slices/groupDetailSlice";
import { getMemberInvitationDetails } from "../../../../../../../../../../services/wGroups/groupMembers";

const OneInvitationDetails = ({ invitationType, details, setDetails, setShowDetails, tabStyles }) => {
    const groupData = useSelector(groupDetails);
    const [tabValue, setTabValue] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const tabProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const msgTypes = details?.message?.map(m => m.type);

    const fetchOneInvitationDetails = (page = 1) => {
        setDetails({ ...details, users: null });
        getMemberInvitationDetails({ groupId: groupData?.id, invitationId: details.id, page: page }).then((res) => {
            setDetails(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchOneInvitationDetails();
    }, []);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#FF8359"
                    }
                }}
                sx={{
                    backgroundColor: '#FFEBE4',
                    maxWidth: { sm: '100%' },
                }}
            >
                <Tab {...tabProps(0)} className={tabStyles.root} label='Guests' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
                <Tab {...tabProps(1)} className={tabStyles.root} label='Message' sx={{
                    width: '50%',
                    opacity: '0.6',
                    color: '#FF8359',
                    textTransform: 'none'
                }} />
            </Tabs>
            <MuiTabPanel value={tabValue} index={0}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {(details.users?.data ?? Array.from(new Array(3))).map((m, index) => (
                        <React.Fragment key={index}>
                            <OneMemberBox m={m} msgTypes={msgTypes} invitationType={invitationType} />
                        </React.Fragment>
                    ))}
                </List>
            </MuiTabPanel>
            <MuiTabPanel value={tabValue} index={1}>
                <Stack spacing={2}>
                    {details.message?.map((m, i) => (
                        <React.Fragment key={i}>
                            <OneMessageBox m={m} />
                        </React.Fragment>
                    ))}
                </Stack>
            </MuiTabPanel>
            <Divider sx={{ my: 1 }} />
            <DialogActions sx={{
                pt: 0,
                justifyContent: tabValue === 0 ? 'space-between' : 'flex-end',
                alignItems: 'center'
            }}>
                {tabValue === 0 ?
                    <InvitedUserPagination
                        activePage={activePage}
                        setActivePage={setActivePage}
                        totalUsers={details?.users?.total ?? 0}
                        per_page_records={details?.users?.per_page}
                        fetchOneInvitationDetails={fetchOneInvitationDetails}
                    />
                    : null
                }
                <Button type="submit" color='warning' variant="outlined"
                    onClick={() => setShowDetails(false)}
                    sx={{
                        boxShadow: 'none'
                    }}>
                    Done
                </Button>
            </DialogActions>
        </>
    )
};


const OneMemberBox = ({ m, msgTypes, invitationType }) => {
    const [activeItemKey, setActiveItemKey] = useState(null);
    const handleClick = (id) => {
        if (id === activeItemKey) {
            setActiveItemKey(null);
        } else {
            setActiveItemKey(id);
        }
    };
    return (
        <>
            <ListItem
                onClick={() => invitationType === 'completed' ? handleClick(m?.id) : null}
                sx={{
                    backgroundColor: activeItemKey === m?.id ? '#F1F1F1' : 'white',
                    ":hover": {
                        cursor: 'pointer',
                        backgroundColor: '#F1F1F1',
                        transition: '0.5s'
                    }
                }}>
                {m ?
                    <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: '#FFEBE4' }} />
                    </ListItemAvatar> :
                    <Skeleton variant="circular" width={40} height={40} />
                }
                <ListItemText
                    primary={m ? m.full_name : <Skeleton width='90%' height='1.75rem' sx={{ ml: 2 }} />}
                    secondary={m ? `+ ${m?.dialing_code} ${m?.mobile}` : <Skeleton width='60%' sx={{ ml: 2 }} />} />
                {invitationType === 'completed' ?
                    (activeItemKey === m?.id ? <ExpandLess /> : <ExpandMore />)
                    : null
                }
            </ListItem>
            <Collapse in={activeItemKey === m?.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <InvitedUserMessageStatus msgTypes={msgTypes} invitedUserDetails={m} />
                </List>
            </Collapse>
        </>
    )
}

const OneMessageBox = ({ m, }) => {
    return (
        <>
            <Box>
                <Typography>{_.capitalize(m.type)}</Typography>
                {m.type === 'email' ? <Editor
                    apiKey="dsiirqxfbbshbtto49hmn3aplhh22hnefwjei883n0nyg0no"
                    initialValue={m.message}
                    disabled={true}
                    init={{
                        height: '65vh',
                        readonly: 1,
                        menubar: false,
                        toolbar: false,
                    }}
                /> :
                    <TextField
                        fullWidth
                        multiline
                        color="warning"
                        defaultValue={m.message}
                        inputProps={{ readOnly: true }}
                        sx={{ mt: 0.5 }}
                    />
                }
            </Box>
        </>
    )
}
export default OneInvitationDetails;
