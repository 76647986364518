import { Toolbar } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import DialogBox from "../../../../../../../../components/DialogBox";

const AboutProgramContent = (desc) => {
    return (
        <>
            {/* <Typography sx={{ wordBreak: 'break-word' }}>{desc}</Typography> */}
            <Editor
                apiKey="dsiirqxfbbshbtto49hmn3aplhh22hnefwjei883n0nyg0no"
                initialValue={desc}
                disabled={true}
                init={{
                    height: '65vh',
                    readonly: 1,
                    menubar: false,
                    toolbar: false,
                }}
            />
            <Toolbar />
        </>
    )
}
const AboutEventDialog = ({ handleClose, desc }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='About Event' content={AboutProgramContent(desc)}
        />
    )
};

export default AboutEventDialog;
