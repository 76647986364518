import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    styled,
    Skeleton,
    useMediaQuery,
    IconButton,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import noGuests from '../../../../../../../../assets/NoGuests.webp';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import { answerEventPoll, getVisiblePoll } from '../../../../../../../../services/wEvents/event';
import { LoadingButton } from '@mui/lab';

const StyledRadio = styled(Radio)(({ theme }) => ({
    '&.MuiRadio-root': {
        color: '#FF8A00',
    },
    '&.Mui-checked': {
        color: '#FF8A00',
    }
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiFormControlLabel-label': {
        fontSize: '0.9rem',
    }
});

const PollTab = ({ poll, isOwner, selections, handleChange, handleInfoClick, pollPayload, eventDetail, handleAnswerSubmit }) => (
    <Box
        sx={{
            p: 2,
            border: '1px solid #eee',
            borderRadius: '1rem',
            my: '1rem',
            boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)'
        }}
    >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">
                {poll.order}.  {poll.question}
            </Typography>
            {(Boolean(poll.answer_visible) || isOwner) &&
                <Tooltip title='View Answers'>
                    <IconButton
                        size="small"
                        onClick={() => handleInfoClick(poll.id)}
                    >
                        <InfoIcon color="warning" />
                    </IconButton>
                </Tooltip>
            }
        </Box>
        <FormControl component="fieldset" fullWidth>
            <RadioGroup value={poll.type === 'checkbox' ? '' : selections[poll.id]} >
                {poll.options.map((option, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                        <StyledFormControlLabel
                            value={option}
                            control={<StyledRadio checked={poll.type === 'checkbox' ? selections[poll.id]?.includes(option) : selections[poll.id] === option} />}
                            label={option}
                            onClick={(event) => {
                                event.preventDefault();
                                handleChange(poll.id, poll.type)({ target: { value: option } });
                            }}
                        />
                    </Box>
                ))}
            </RadioGroup>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <LoadingButton
                type="submit"
                color="warning"
                variant="contained"
                disabled={poll?.is_submit == 1 && poll?.again_submit == 0}
                onClick={() => handleAnswerSubmit(pollPayload, poll.id, eventDetail)}
            >
                Submit
            </LoadingButton>
        </Box>
    </Box>
);

const PollsTab = ({ handleInfoClick }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [polls, setPolls] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const eventData = useSelector(eventDetails);
    const [selections, setSelections] = useState({});
    const [pollPayload, setPollPayload] = useState({});
    const debounceTimers = useRef({});

    const isSmallDevice = useMediaQuery("(max-width:600px)");
    const handleAnswerSubmit = (payload, id, eventDetail) => {

        answerEventPoll({ 'answer': payload[id] }, eventDetail?.id, id)
            .then(response => {
                toast.success(response.data.message, {
                    position: "top-right",
                    theme: "dark"
                });
                fetchPolls();
            })
            .catch(err => {
                toast.error('Failed to answer poll', {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    const fetchPolls = async () => {
        try {
            const response = await getVisiblePoll(eventData?.id);
            const pollData = response.data.data;
            setPolls(pollData);
            setIsOwner(Boolean(response.data.is_owner));

            if (pollData?.length > 0) {
                const initialSelections = pollData.reduce((acc, poll) => {
                    acc[poll.id] = poll.answer ? poll.answer : (poll.type === 'checkbox' ? [] : '');
                    return acc;
                }, {});
                setSelections(initialSelections);
                setError(null);
            } else {
                setError('Waiting for polls to show.');
            }

        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (eventData?.id) {
            fetchPolls();
        }
    }, [eventData?.id]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchPolls();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleChange = (pollId, type) => (event) => {
        const value = event.target.value;
        setSelections(prev => {
            const newSelections = { ...prev };

            if (type === 'checkbox') {
                if (!newSelections[pollId]) {
                    newSelections[pollId] = [];
                }
                newSelections[pollId] = newSelections[pollId].includes(value)
                    ? newSelections[pollId].filter(item => item !== value)
                    : [...newSelections[pollId], value];
            } else {
                if (newSelections[pollId] === value) {
                    return prev;
                }
                newSelections[pollId] = value;
            }

            setPollPayload(newSelections);
            return newSelections;
        });
    };

    if (loading) return (
        <>
            {
                [1, 2].map(() => (
                    <Box
                        sx={{
                            p: 2,
                            border: '1px solid #eee',
                            borderRadius: '1rem',
                            my: '1rem',
                            mb: '2rem',
                            boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)'
                        }}
                    >
                        <Box sx={{ display: 'flex', marginBottom: 1, alignItems: 'center', width: '100%' }}>
                            <Skeleton variant="text" width="80%" height={40} sx={{ borderRadius: '.5rem' }} />
                            <Skeleton variant="circular" sx={{ width: 25, height: 25, marginLeft: 'auto' }} />
                        </Box>
                        {[1, 2, 3, 4].map(() => (
                            <Box sx={{ display: 'flex', marginBottom: 1, alignItems: 'center', width: '100%' }}>
                                <Skeleton variant="circular" sx={{ width: 20, height: 20 }} />
                                <Skeleton variant="text" width="80%" height={30} sx={{ borderRadius: '.5rem', marginLeft: '.5rem' }} />
                            </Box>
                        ))}
                        <Skeleton variant="text" width="50%" height={60} sx={{ borderRadius: '.5rem', justifySelf: 'center' }} />
                    </Box>
                ))
            }
        </>
    );

    if (!polls || polls.length === 0 || error) return (
        <Box sx={{ textAlign: 'center' }}>
            {error &&
                <Typography color="error" p={2} sx={{ textAlign: 'center' }}>{error}</Typography>
            }
            <Box
                component='img'
                src={noGuests}
                width={isSmallDevice ? '100%' : '30rem'}
                height='25rem'
            />
        </Box>
    );

    return (
        <Box sx={{ width: '100%' }}>
            {polls.map((poll) => (
                <PollTab
                    key={poll.id}
                    poll={poll}
                    isOwner={isOwner}
                    selections={selections}
                    handleChange={handleChange}
                    handleInfoClick={handleInfoClick}
                    pollPayload={pollPayload}
                    eventDetail={eventData}
                    handleAnswerSubmit={handleAnswerSubmit}
                />
            ))}
        </Box>
    );
};

export default PollsTab;
