import React, { useEffect, useState, memo } from 'react';
import {
    Box,
    Typography,
    Stack,
    CircularProgress,
    Pagination,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Button,
    Avatar,
    useMediaQuery
} from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { downloadEventPollReports, getEventPollReports } from '../../../../../../../../services/wEvents/event';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import noGuests from '../../../../../../../../assets/NoGuests.webp';


const stringAvatar = (name) => {
    const initials = name.split(' ').map(word => word[0]).join('');
    return {
        children: initials,
    };
};

const GuestAvatar = memo(({ name }) => {
    return (
        <Avatar {...stringAvatar(name.trim())} />
    )
});

const PollReportsTab = ({ pollId, handleBackClick, handleDownload = null }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pollReports, setPollReports] = useState([]);
    const [poll, setPoll] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const eventData = useSelector(eventDetails);

    const isSmallDevice = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        const fetchPollReports = async () => {
            try {
                const response = await getEventPollReports(eventData?.id, pollId, page);
                const data = response.data.data;
                setPoll(data.poll);
                console.log('|||| ', response.data);

                setPollReports(data.answers.data);
                setTotalPages(data.answers.last_page);
            } catch (err) {
                setError('Failed to load poll reports');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        if (eventData?.id && pollId) {
            fetchPollReports();
        }
    }, [eventData?.id, pollId, page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const ListItemSkeleton = () => (
        <ListItem>
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton width="60%" />}
                secondary={<Skeleton width="40%" />}
            />
        </ListItem>
    );

    if (loading) return (
        <Box display="flex" justifyContent="center" p={3} sx={{ flexWrap: 'wrap' }}>
            {[1, 2, 3, 4, 5,].map(() => (<ListItemSkeleton />))}
        </Box>
    );

    if (error) return (
        <Typography color="error" p={2} sx={{ textAlign: 'center' }}>{error}</Typography>
    )

    if (!pollReports || pollReports.length === 0) return (
        <>
            <Typography onClick={handleBackClick} my={'.5rem'} color="warning" sx={{ cursor: 'pointer' }}>
                &lt; Back
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
                <Box
                    component='img'
                    src={noGuests}
                    width={isSmallDevice ? '100%' : '30rem'}
                    height='25rem'
                />
            </Box>
        </>
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Typography onClick={handleBackClick} my={'.5rem'} color="warning" sx={{ cursor: 'pointer' }}>
                &lt; Back
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
                {typeof handleDownload === 'function' && (
                    <LoadingButton
                        loading={downloadLoading}
                        loadingPosition="start"
                        startIcon={<DownloadIcon />}
                        variant="outlined"
                        color='warning'
                        sx={{ boxShadow: 'none' }}
                        onClick={handleDownload}
                    >
                        Download Reports
                    </LoadingButton>
                )}
            </Box>
            <Stack spacing={2}>
                <List>
                    {pollReports.length === 0 ? (
                        Array(10).fill(0).map((_, index) => (
                            <ListItemSkeleton key={index} />
                        ))
                    ) : (
                        pollReports.map((report) => (
                            <Stack
                                key={report.id}
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{ p: '1rem', my: '1rem', boxShadow: '0 0 3px 3px rgba(0,0,0,0.15)', borderRadius: '.75rem' }}
                            >
                                {report.full_name ?
                                    <GuestAvatar name={report.full_name || ''} />
                                    : <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                }
                                <Box sx={{ width: '100%' }}>
                                    <Box variant="body1" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                        <Typography variant="body1">
                                            {report.full_name}
                                        </Typography>
                                        <Typography variant="body2" sx={{ maxWidth: '50%' }}>
                                            {report.answer}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="textSecondary">
                                        {new Date(report.created_at).toLocaleString()}
                                    </Typography>
                                </Box>
                            </Stack>
                        ))
                    )}
                </List>
            </Stack>
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
                color="warning"
                sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
            />
        </Box>
    );
};

export default PollReportsTab;