import axios from "axios";
import ls from 'local-storage';

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before the request is sent
        const token = ls.get('Token');
        const contentType = config.headers['content-type'];

        // Check if content is form data, don't set content-type for FormData
        if (config.data instanceof FormData) {
            config.headers = {
                ...config.headers,
                "Authorization": `${token ? "Bearer " + token : null}`
            };
        } else {
            config.headers = {
                ...config.headers,
                "Content-Type": !contentType && "application/json",
                "Authorization": `${token ? "Bearer " + token : null}`
            };
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);
