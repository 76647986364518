import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: 'Sidebar',
    initialState: { isSidebarOpen: true },
    reducers: {
        change_sidebar_status: (state, action) => {
            state.isSidebarOpen = action.payload;
        }
    }
});

export const sidebarShortcutsSlice = createSlice({
    name: 'SidebarShortcuts',
    initialState: { sidebarShortcuts: [] },
    reducers: {
        set_sidebar_shortcuts: (state, action) => {
            state.sidebarShortcuts = action.payload;
        },
        clear_sidebar_shortcuts: (state) => {
            state.sidebarShortcuts.length = 0;
        }
    }
});

export const { set_sidebar_shortcuts, clear_sidebar_shortcuts } = sidebarShortcutsSlice.actions;
export const { change_sidebar_status } = sidebarSlice.actions;

export const sidebarStatus = (state) => state.Sidebar.isSidebarOpen;
export const sidebarShortcutsTabs = (state) => state.SidebarShortcuts.sidebarShortcuts;

export const sidebarReducer = sidebarSlice.reducer;
export const sidebarShortcutsReducer = sidebarShortcutsSlice.reducer;
