export const BACKEND_URL =
    process.env.REACT_APP_ENV === "development"
        ? process.env.REACT_APP_DEV_URL
        : process.env.REACT_APP_PROD_URL;
export const API_BASE_URL = BACKEND_URL + "/api/";

// User Endpoints
export const AUTH_API = {
    LOGIN_AND_SEND_OTP: API_BASE_URL + "send-otp",
    VERIFY_OTP: API_BASE_URL + "login-app",
    USER_DETAILS: API_BASE_URL + "users"
};

export const USER_KYC_API = API_BASE_URL + "user/:user_id/kyc";

export const BANK_API = API_BASE_URL + "user/:user_id/banks";

export const USERS_LINK_BANK_ACCOUNT =
    API_BASE_URL + "banks/:bank_id/create-link-bank-account";

export const PAYMENT_API = {
    FETCH_PAYMENTS: API_BASE_URL + "payments/all",
    FETCH_PAYMENT_DETAILS: API_BASE_URL + "payments/:model_id",
    CREATE_ORDER: API_BASE_URL + "paymentgateway/createorder",
    CASH_PAYMENT: API_BASE_URL + "payment/method/cash"
};

export const CATEGORIES_API = API_BASE_URL + "categories";

export const USERS_INVITE_MESSAGE =
    API_BASE_URL + "model/:model_id/invitations";
export const USERS_INVITE_DETAILS =
    API_BASE_URL + "model/:model_id/invitations/:invite_id";

export const EVENT_API = {
    EVENTS: API_BASE_URL + "events",
    PUBLIC_EVENT: API_BASE_URL + "event/:event_id/public",
    EVENT_WEBLINK: API_BASE_URL + "event/weblink",
    GROUP_WEBLINK_EVENTS: API_BASE_URL + "group/weblink/events",
    DOWNLOAD_EVENT_QR_FOR_REGISTRATION_SELF_CHECKIN: API_BASE_URL + "event/:event_id/commonqr/download",
    EVENT_MEDIA: API_BASE_URL + "events/:event_id/eventmedia", // For main event image
    EVENT_PROGRAM: API_BASE_URL + "events/:event_id/eventprogram", // For event programs
    EVENT_MEMBER: API_BASE_URL + "events/:event_id/eventmember", // For event key members
    EVENT_ALBUM: API_BASE_URL + "events/:event_id/eventalbum", // For event albums
    EVENT_ALBUM_MEDIA: API_BASE_URL + "eventalbums/:album_id/eventmedia", // For album media
    EVENT_TICKET: API_BASE_URL + "events/:event_id/eventticket", //For event tickets
    EVENT_TICKET_HIDE: API_BASE_URL + "events/:event_id/ticket/:ticket_id/toggle-visibility", //For event tickets
    EVENT_TICKET_SOLD: API_BASE_URL + "events/:event_id/ticket/:ticket_id/sold", //For event tickets
    EVENT_TICKET_QR_PASSES: API_BASE_URL + "events/:event_id/ticket/:ticket_id/qr-passes",
    EVENT_TICKET_SEND_PASSES: API_BASE_URL + "events/:event_id/ticket/:ticket_id/send-passes",
    SELECT_EVENT_TICKET: API_BASE_URL + "events/:event_id/ticket/select",
    SELECT_PUBLIC_EVENT_TICKET: API_BASE_URL + "events/:event_id/commonEvent/ticket/select",
    SUBSTITUTE_GUEST_TICKETS: API_BASE_URL + "events/:event_id/substitute/ticket/store",
    EVENT_FORM: API_BASE_URL + "events/:event_id/eventregistrationforms", //For event forms
    EVENT_POLL: API_BASE_URL + "events/:event_id/eventpolls", //For event forms
    EVENT_FORM_WITHOUT_LOGIN:
        API_BASE_URL + "events/:event_id/registration/status", //For event forms
    EVENT_FORM_FIELDS:
        API_BASE_URL + "events/:event_id/eventregistrationformfields",
    EVENT_POLL_FIELDS:
        API_BASE_URL + "events/:event_id/eventpolls/:poll",
    EVENT_POLL_DOWNLOAD: API_BASE_URL + "events/:event_id/eventpolls/exportpoll",
    EVENT_FORM_ANSWERS:
        API_BASE_URL + "events/:event_id/registrationform/answer", //For event forms
    EVENT_FORM_ANSWERS_DOWNLOAD:
        API_BASE_URL + "events/:event_id/registrationform/replies/download", //For event form replies download
    PUBLIC_EVENT_FORM_ANSWERS:
        API_BASE_URL + "events/:event_id/commonEvent/registrationform/answer", //For event forms
    EVENT_RSVP: API_BASE_URL + "events/:event_id/eventrsvp",
    EVENT_WEBLINK_RSVP_ANSWERS:
        API_BASE_URL + "eventweblink/:event_id/eventrsvpcount",
    EVENT_RSVP_ANSWERS: API_BASE_URL + "events/:event_id/eventrsvpanswer",
    EVENT_QUICK_RSVP:
        API_BASE_URL + "events/:event_id/eventrsvpanswer/quickrsvp",
    EVENT_RSVP_DOWNLOAD: API_BASE_URL + "events/eventrsvp/:event_id/download",
    EVENT_PAID_PAYMENTS: API_BASE_URL + "events/:event_id/payments/paid",
    EVENT_PAID_PAYMENT_DETAILS: API_BASE_URL + "events/:event_id/payments/paid/:guest_ticket_id",
    EVENT_RECEIVED_PAYMENTS: API_BASE_URL + "events/:event_id/payments/received",
    EVENT_RECEIVED_PAYMENT_DETAILS: API_BASE_URL + "events/:event_id/payments/received/:guest_ticket_id",
    EVENT_MODEL_VIEW: API_BASE_URL + "views",
    REORDER_EVENT_MODEL_VIEWS: API_BASE_URL + "views/reorder",
    GENERATED_TOKEN: API_BASE_URL + "events/:event_id/generated-token",
};

export const EVENT_GUESTS_API = {
    VERIFY_EVENT_GUESTS:
        API_BASE_URL + "events/:event_id/eventuser/verifyqrcode",
    CHECK_IN_GUEST: API_BASE_URL + "events/:event_id/eventuser/checkin",
    CHECK_IN_RECORDS_GUEST: API_BASE_URL + "events/:event_id/eventuser/checkin/records",
    SCANNING_REPORTS: API_BASE_URL + "events/:event_id/scanning/reports",
    CHECK_IN_RECORDS: API_BASE_URL + "events/:event_id/ticket/:ticket_id/checkin/records",
    CHECK_IN_RECORDS_WITHOUT_TICKETS: API_BASE_URL + "events/:event_id/checkin/records",
    CHECKED_IN_TICKETS: API_BASE_URL + "events/:event_id/checkin/tickets",
    CHECK_IN_RECORDS_COUNT: API_BASE_URL + "events/:event_id/ticket/:ticket_id/checkin/records/count",
    CHECK_IN_RECORDS_DOWNLOAD:
        API_BASE_URL + "events/:event_id/ticket/:ticket_id/checkin/records/download", //For event check ins report download
    CHECK_IN_RECORDS_DOWNLOAD_WITHOUT_TICKETS:
        API_BASE_URL + "events/:event_id/checkin/records/download", //For event check ins report download without tickets
    EVENT_GUEST_ADDITIONAL_DETAILS: API_BASE_URL + "events/:event_id/eventuser/getguestdetails",
    EVENT_GUESTS: API_BASE_URL + "events/:event_id/eventuser",
    SUBSTITUTE_EVENT_GUESTS: API_BASE_URL + "events/:event_id/substituteeventuser",
    EVENT_GUESTS_TICKET_COUNT: API_BASE_URL + "events/:event_id/substituteeventuser/getavailabletickets",
    SUBSTITUTE_USERS: API_BASE_URL + "events/:event_id/substituteuser",
    EVENT_GUESTS_DETAILS:
        API_BASE_URL + "eventweblink/:event_id/eventuser/:guest_uuid",
    PUBLIC_EVENT_GUESTS_DETAILS:
        API_BASE_URL + "eventcommonlink/:event_id/eventuser",
    EVENT_GUESTS_IMPORT: API_BASE_URL + "events/:event_id/eventuser/import",
    EVENT_GUESTS_DELETE: API_BASE_URL + "events/:event_id/eventuser/bulkdelete",
    EVENT_GUESTS_ADD_GROUP_MEMBERS:
        API_BASE_URL + "events/:event_id/eventuser/bulkinsert/groupmembers"
};

export const GROUP_API = API_BASE_URL + "groups";
export const GROUP_WEBLINK = API_BASE_URL + "group/weblink";
export const GROUP_DOCS_API = GROUP_API + "/:group_id/document";
export const GROUP_SUBSCRIPTION_API = {
    GROUP_SUBSCRIPTION: GROUP_API + "/:group_id/subscription",
    GROUP_SUBSCRIPTION_PURCHASED: GROUP_API + "/:group_id/subscription/:subscription_id/purchased",
    GROUP_SUBSCRIPTION_PAYMENTS: GROUP_API + "/:group_id/subscriptions/payments",
    GROUP_SUBSCRIPTION_PAYMENT_DETAILS: GROUP_API + "/:group_id/subscriptions/payments/:member_subscription_id",
    SELECT_GROUP_SUBSCRIPTION: GROUP_API + "/:group_id/subscription/select"
};

export const GROUP_REGISTRATION_FORM_API = {
    GROUP_FORM: GROUP_API + "/:group_id/groupregistrationforms", //For group forms
    GROUP_FORM_WITHOUT_LOGIN: GROUP_API + "/:group_id/registration/status", //For group forms
    GROUP_FORM_FIELDS: GROUP_API + "/:group_id/groupregistrationformfields",
    GROUP_FORM_ANSWERS: GROUP_API + "/:group_id/registrationform/answer", //For group forms
    GROUP_FORM_ANSWERS_DOWNLOAD: GROUP_API + "/:group_id/registrationform/replies/download", //For event form replies download
};

export const GROUP_KEY_PERSONS = GROUP_API + "/:group_id/groupkeyperson";
export const GROUP_ALBUM_API = {
    GROUP_ALBUM: GROUP_API + "/:group_id/groupalbum", // For group albums
    GROUP_ALBUM_MEDIA: API_BASE_URL + "groupalbums/:album_id/groupmedia" // For album media
};

export const GROUP_MEMBERS_API = {
    GROUP_MEMBERS: GROUP_API + "/:group_id/groupmember",
    GROUP_MEMBERS_DETAILS:
        API_BASE_URL + "groupweblink/:group_id/groupmember/:member_uuid",
    GROUP_MEMBERS_IMPORT: GROUP_API + "/:group_id/groupmember/import",
    GROUP_MEMBERS_DELETE: GROUP_API + "/:group_id/groupmember/bulkdelete",
    GROUP_MEMBER_JOIN_GROUP: GROUP_API + "/:group_id/groupmember/joingroup",
    GROUP_MEMBERS_INVITE_MESSAGE: API_BASE_URL + "model/:group_id/invitations",
    GROUP_MEMBERS_INVITE_DETAILS:
        API_BASE_URL + "model/:group_id/invitations/:invite_id",
};

export const MESSAGE_TEMPLATE_API = API_BASE_URL + 'invitation-templates';
export const SEND_TICKET_EXISTINGUSER_API = API_BASE_URL + 'send-ticket';