import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Collapse, DialogActions, IconButton, Menu, Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DialogBox from "../../../../../../../../components/DialogBox";
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import { editEventGuest, verifyGuestQR } from '../../../../../../../../services/wEvents/eventGuests';
import { EventGuestsContext } from '../../EventGuestContext';
import DeleteEventGuest from "./DeleteEventGuest";
import EditEventGuest from "./EditEventGuest";
import { convertUtcToLocal } from '../../../../../../../../utils/functions/timeConversion';
import moment from 'moment';
import { ticketCurrencies } from '../../../../../../../../utils/constants/ticketCurrency';
import { parseDecimalNumber } from '../../../../../../../../utils/functions/getFormattedNumber';
import { ALL_PERMISSION_ROLES } from '../../../../../../../../utils/constants/roles';
import _ from 'lodash';
import EventQrCodeDialog from '../../../../../EventDetailCards/components/EventQrcode';
import GuestDetailsTable from './GuestDetailsTable';
import PrintIcon from '@mui/icons-material/Print';
import { sendTicket } from '../../../../../../../../services/wEvents/ticketTemplates';

const GuestDetailContent = (guestDetails, additionalDetails, handleClose) => {
    const eventData = useSelector(eventDetails);
    const { setGuests, setupdatedCounts, updatedCategoryCounts } = useContext(EventGuestsContext);

    const [edit, setEdit] = useState(false); // For open edit form conditionally
    const [loading, setLoading] = useState(false);
    const [isSendingTicket, setIsSendingTicket] = useState(false);
    const [checkInLoader, setCheckInLoading] = useState(false);
    const [qrCodeDialog, setQrCodeDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteAnchorEl, setDeleteAnchorEl] = useState(null); // For delete popover
    const [showFormReplies, setFormReplies] = useState(false);
    const [maxValueObj, setMaxValueObj] = useState({ available_tickets: 0, facility_availability_status: [] });
    const [guestData, setGuestData] = useState();
    const [ticketData, setTicketData] = useState();
    const [checkInData, setCheckInData] = useState();
    const [openPrintDialog, setOpenPrintDialog] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const isAllPermission = ALL_PERMISSION_ROLES.includes(eventData?.current_user_role);
    const scanPermission = eventData?.current_user_role === 'manager';
    const paidRegistrationColumns = {
        'Ticket Name': 'ticket_title', 'Ticket ID': 'ticket_id', [guestDetails?.generated_by_owner ? 'Tickets' : 'Tickets Purchased']: 'tickets_bought',
        'Amount Paid': 'amount_paid', 'Payment ID': 'payment_id', 'Payment Method': 'payment_method'
    };
    let freeRegistrationColumns = {
        'Ticket Name': 'ticket_title', 'Ticket ID': 'ticket_id', [guestDetails?.generated_by_owner ? 'Tickets' : 'Tickets Bought']: 'tickets_bought',
    };
    if (!eventData?.is_paid && additionalDetails?.ticket_data?.payment_id) {
        freeRegistrationColumns = { ...freeRegistrationColumns, 'Amount Paid': 'amount_paid', 'Payment ID': 'payment_id', 'Payment Method': 'payment_method' };
    }

    let amountCurrencySymbol = null;
    if (additionalDetails?.ticket_data?.amount_currency) {
        amountCurrencySymbol = ticketCurrencies.find((t) => t.value === additionalDetails?.ticket_data?.amount_currency)?.symbol;
    }
    let filteredFormReplies = [];
    if (additionalDetails?.additional_form_replies?.length > 0) {
        filteredFormReplies = additionalDetails?.additional_form_replies.filter(item => item.answer !== null);
    }
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleEditClick = () => {
        setEdit(true);
        setAnchorEl(null);
    }
    const handleDeleteClick = (e) => {
        setDeleteAnchorEl(e.currentTarget)
    }

    const handleSendTicket = () => {
        setIsSendingTicket(true);

        sendTicket(guestDetails).then((res) => {
            if (res.data.success) {
                toast.success("Ticket sent successfully!!", {
                    position: "top-right",
                    theme: "dark"
                });
                setIsSendingTicket(false);
                handleClose();
            } else {
                toast.error("This user have no ticket data!!", {
                    position: "top-right",
                    theme: "dark"
                });
                setIsSendingTicket(false);
            }
        }).catch((err) => {
            console.log(err);
            setEdit(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    }
    const handleRoleChangeClick = () => {
        setLoading(true);
        const payload = {
            _method: "PUT",
            role: guestDetails.role === "manager" ? "guest" : "manager"
        }
        editEventGuest(payload, eventData?.id, guestDetails.id).then((res) => {
            console.log('---------------->', res.data);
            toast.success("Role updated successfully!!", {
                position: "top-right",
                theme: "dark"
            });
            setGuests((guests) => {
                let ele = guests.find(i => i.id === res.data?.data?.id);
                guests[guests.indexOf(ele)] = res.data.data;
                return [...guests];
            })
            setLoading(false);
            handleClose();
        }).catch((err) => {
            console.log(err);
            setEdit(false);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })
    }

    const handleQrCodeClose = (event, reason, operation) => {
        if (reason && reason === "backdropClick") // For not closing dialog on clicking outside of dialog
            return;
        document.body.classList.remove("no-scroll");
        setQrCodeDialog(false);
    }

    const handleCheckInVerification = () => {
        setCheckInLoading(true);
        if (!eventData?.is_self_check_in) {
            const payload = {
                qrGuestUuid: guestDetails?.uuid,
            }

            verifyGuestQR(eventData?.id, payload).then((result) => {

                const checkInCounts = _.map(result.data.check_in_data, 'check_in_count').reverse();
                setQrCodeDialog(true);
                setCheckInLoading(false);
                setGuestData(result.data.guest_data[0]);
                setTicketData(result.data.ticket_data ?? null);
                setCheckInData(checkInCounts);
                setupdatedCounts(result.data.available_category_tickets)
                // setMaxValue(result.data.available_tickets);
                setMaxValueObj({
                    available_tickets: result.data.available_tickets,
                    facility_availability_status: result.data.facility_availability_status
                })
                toast.success(result.data.message, {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                setCheckInLoading(false);
                toast.error(err.response.data.error || 'Something went wrong, Try again!', {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }
    }

    const handlePrintClick = () => {
        setIsPrint(true);
        setIsDownload(false);
        setOpenPrintDialog(true);
    };

    const handleDownloadClick = () => {
        setIsPrint(false);
        setIsDownload(true);
        setOpenPrintDialog(true);
    };

    const handlePrintDialogClose = () => {
        setOpenPrintDialog(false);
    };

    return (
        <>
            {!additionalDetails ?
                <Skeleton animation="wave" variant="rectangular" height='12rem' sx={{ mb: 1 }} /> :
                <>
                    {(!edit && isAllPermission) ?
                        <Box sx={{ position: 'relative', float: 'right' }}>
                            <Tooltip title="Options">
                                <IconButton
                                    onClick={handleMenuOpen}
                                    size="small"
                                    sx={{ p: 0 }}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1,
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                MenuListProps={{
                                    sx: {
                                        py: '0.25rem'
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Stack direction='row' alignItems='center' spacing={0.5} sx={{
                                    ":hover": { backgroundColor: '#dadada', cursor: 'pointer' },
                                    pl: '0.5rem', pr: '1rem', width: '100%'
                                }}>
                                    <EditIcon fontSize='2rem' sx={{ color: '#1F274A' }} />
                                    <Typography onClick={handleEditClick} sx={{
                                        flex: 1,
                                        lineHeight: '2rem'
                                    }}>Edit</Typography>
                                </Stack>
                                <Stack direction='row' alignItems='center' spacing={0.5} sx={{
                                    ":hover": { backgroundColor: '#dadada', cursor: 'pointer' },
                                    pl: '0.5rem', pr: '1rem', width: '100%'
                                }}>
                                    <DeleteIcon fontSize='2rem' sx={{ color: '#1F274A' }} />
                                    <Typography onClick={(e) => handleDeleteClick(e)} sx={{
                                        flex: 1,
                                        lineHeight: '2rem'
                                    }}>Delete</Typography>
                                </Stack>
                                <DeleteEventGuest guestId={guestDetails.id} handleClose={handleClose}
                                    anchorEl={deleteAnchorEl} setAnchorEl={setDeleteAnchorEl}
                                />
                            </Menu>
                        </Box >
                        : null
                    }
                    {
                        edit ? <EditEventGuest guestDetails={guestDetails} setEdit={setEdit} /> :
                            <Box sx={{ mb: 1 }}>
                                <Stack direction='row' spacing={2} alignItems='center' sx={{ wordBreak: 'break-all' }}>
                                    <Typography sx={{ flex: 1, fontWeight: 500, fontSize: '1.1rem' }}>
                                        Name: {guestDetails?.name} {guestDetails?.invited_by_user_name ? `(Substitute of ${guestDetails?.invited_by_user_name})` : null}
                                    </Typography>
                                </Stack>
                                <Stack spacing={1.5} sx={{ mt: 1.5 }}>
                                    {guestDetails?.email ?
                                        <Typography sx={{ fontSize: '1rem' }}>
                                            Email: {guestDetails?.email}
                                        </Typography>
                                        : null
                                    }
                                    {guestDetails?.dialing_code && guestDetails?.mobile ? (
                                        <Typography sx={{ fontSize: '1rem' }}>
                                            Contact: +{guestDetails.dialing_code} {guestDetails.mobile}
                                        </Typography>
                                    ) : null
                                    }
                                    {guestDetails?.event_user_photo_path ? (
                                        <>

                                            <Typography sx={{ fontSize: '1rem' }}>
                                                Profile Photo:
                                            </Typography>
                                            <Box component="img" src={guestDetails?.event_user_photo_path} alt="uploaded file" sx={{ width: '100%', maxWidth: 200, mt: 1 }} />
                                        </>
                                    ) : null
                                    }
                                    {additionalDetails?.registration_time ?
                                        <Typography sx={{ fontSize: '1rem' }}>
                                            Registration Time: {convertUtcToLocal(additionalDetails?.registration_time).format("Do MMMM YYYY, h:mm A")}
                                        </Typography> :
                                        null
                                    }
                                    {additionalDetails?.ticket_data ?
                                        <>
                                            {Object.entries(eventData?.is_paid ? paidRegistrationColumns : freeRegistrationColumns)?.map(([key, value]) => (
                                                <React.Fragment key={key}>
                                                    {additionalDetails?.ticket_data[value] ?
                                                        <>
                                                            {
                                                                key === 'Amount Paid' ?
                                                                    <Typography key={key} sx={{ fontSize: '1rem' }}>
                                                                        {key}: {parseDecimalNumber(additionalDetails?.ticket_data[value], 2)} <span dangerouslySetInnerHTML={{ __html: amountCurrencySymbol }} />
                                                                    </Typography> :
                                                                    <Typography key={key} sx={{ fontSize: '1rem' }}>
                                                                        {key}: {additionalDetails?.ticket_data[value]}
                                                                    </Typography>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </> :
                                        null
                                    }
                                    {
                                        // payment time is in epoch timestamp
                                        additionalDetails?.ticket_data?.payment_time ?
                                            <Typography sx={{ fontSize: '1rem' }}>
                                                Payment Time: {moment(new Date(additionalDetails?.ticket_data?.payment_time * 1000)).format("D MMM YYYY, h:mm A")}
                                            </Typography> :
                                            null
                                    }
                                    {
                                        additionalDetails?.ticket_data?.tickets_bought_time ?
                                            <Typography sx={{ fontSize: '1rem' }}>
                                                Tickets Bought Time: {convertUtcToLocal(additionalDetails?.ticket_data?.tickets_bought_time).format("Do MMMM YYYY, h:mm A")}
                                            </Typography> :
                                            null
                                    }
                                    {
                                        additionalDetails?.registered_by ?
                                            <Typography sx={{ fontSize: '1rem' }}>
                                                Registered By: {additionalDetails?.registered_by}
                                            </Typography> :
                                            null
                                    }
                                    {
                                        guestDetails?.extra_csv_details ? (
                                            Object.entries(guestDetails.extra_csv_details).map(([key, value]) => {
                                                // Handle multiple categories and counts for FixCategory and FixCount
                                                if (key === "FixCategory" && guestDetails.extra_csv_details["FixCount"]) {
                                                    const categories = value.split(",");
                                                    const counts = updatedCategoryCounts ? updatedCategoryCounts : guestDetails.extra_csv_details["FixCount"].split(",");
                                                    return (
                                                        <Typography sx={{ fontSize: '1rem' }} key={key}>
                                                            Guest Category: {categories.map((cat, index) => `${cat} => ${counts[index]}`).join(", ")}
                                                        </Typography>
                                                    );
                                                }
                                                // Handle single OptionalCategory and OptionalCount
                                                else if (key === "OptionalCategory" && guestDetails.extra_csv_details["OptionalCount"] !== undefined) {
                                                    const optionalCount = guestDetails.extra_csv_details["OptionalCount"];
                                                    return (
                                                        <Typography sx={{ fontSize: '1rem' }} key={key}>
                                                            Optional Category: {value}{optionalCount ? ` = ${optionalCount}` : ""}
                                                        </Typography>
                                                    );
                                                }
                                                // Render other entries normally
                                                else if (key !== "FixCount" && key !== "OptionalCount" && value) {
                                                    return (
                                                        <Typography sx={{ fontSize: '1rem' }} key={key}>
                                                            {key}: {value}
                                                        </Typography>
                                                    );
                                                }
                                                return null;
                                            })
                                        ) : null
                                    }
                                    {
                                        guestDetails?.custom_id ?
                                            <Typography sx={{ fontSize: '1rem' }}>
                                                ID: {guestDetails?.custom_id}
                                            </Typography> : null
                                    }

                                    {
                                        // show only those form replies where guest has filled some answer
                                        filteredFormReplies?.length > 0 ?
                                            <Box>
                                                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                                                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                                        Show additional guest details
                                                    </Typography>
                                                    <Switch
                                                        color='warning'
                                                        checked={showFormReplies}
                                                        onChange={(e) => setFormReplies(e.target.checked)}
                                                    />
                                                </Stack>
                                                <Collapse
                                                    in={showFormReplies}
                                                    unmountOnExit={true}>
                                                    {additionalDetails?.additional_form_replies.map((reply, index) => (
                                                        <React.Fragment key={index}>
                                                            {
                                                                reply.answer ?
                                                                    <Stack
                                                                        key={index} direction='row' alignItems='center' justifyContent='flex-start'
                                                                        sx={{
                                                                            pt: 1.5,
                                                                            gap: "10px"
                                                                        }}>
                                                                        <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                                                            {reply.question}:
                                                                        </Typography>
                                                                        {reply.type === 'checkbox'
                                                                            ? JSON.parse(reply.answer).join(', ')
                                                                            : reply.type === 'file'
                                                                                ? <Box component="img" src={reply.answer} alt="uploaded file" sx={{ width: '100%', maxWidth: 200, mt: 1 }} />
                                                                                : reply.answer ?? '-'}
                                                                    </Stack> :
                                                                    null
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </Collapse>
                                            </Box> :
                                            null
                                    }
                                </Stack>
                                <DialogActions sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <LoadingButton
                                        size="small"
                                        type="submit"
                                        loading={isSendingTicket}
                                        onClick={handleSendTicket}
                                        startIcon={isSendingTicket ? <SaveIcon /> : ''}
                                        loadingPosition={isSendingTicket ? "start" : null}
                                        sx={{
                                            p: '0.4rem',
                                            color: 'white',
                                            borderRadius: '1.1rem',
                                            backgroundColor: isSendingTicket ? 'lightgray' : '#1F274A',
                                            ":hover": {
                                                backgroundColor: '#1F274A',
                                            }
                                        }}
                                    >
                                        Send Ticket
                                    </LoadingButton>
                                    {
                                        isAllPermission ?
                                            <LoadingButton
                                                size="small"
                                                type="submit"
                                                loading={loading}
                                                onClick={handleRoleChangeClick}
                                                startIcon={loading ? <SaveIcon /> : ''}
                                                loadingPosition={loading ? "start" : null}
                                                sx={{
                                                    p: '0.4rem',
                                                    color: 'white',
                                                    borderRadius: '1.1rem',
                                                    backgroundColor: loading ? 'lightgray' : '#1F274A',
                                                    ":hover": {
                                                        backgroundColor: '#1F274A',
                                                    }
                                                }}
                                            >
                                                Make {guestDetails.role === "manager" ? "Guest" : "Manager"}
                                            </LoadingButton> :
                                            null
                                    }
                                    {
                                        !eventData?.is_self_check_in ?
                                            <LoadingButton
                                                size="small"
                                                type="submit"
                                                loading={checkInLoader}
                                                onClick={handleCheckInVerification}
                                                startIcon={checkInLoader ? <SaveIcon /> : ''}
                                                loadingPosition={checkInLoader ? "start" : null}
                                                sx={{
                                                    p: '0.4rem',
                                                    color: 'white',
                                                    borderRadius: '1.1rem',
                                                    backgroundColor: checkInLoader ? 'lightgray' : '#1F274A',
                                                    ":hover": {
                                                        backgroundColor: '#1F274A',
                                                    }
                                                }}
                                            >
                                                Manual CheckIn
                                            </LoadingButton> :
                                            null
                                    }
                                    <LoadingButton
                                        size="small"
                                        type="button"
                                        onClick={handlePrintClick}
                                        sx={{
                                            p: '0.4rem',
                                            color: 'white',
                                            borderRadius: '1.1rem',
                                            backgroundColor: '#1F274A',
                                            ":hover": {
                                                backgroundColor: '#1F274A',
                                            }
                                        }}
                                    >
                                        Print
                                    </LoadingButton>
                                    {/* <LoadingButton
                                    size="small"
                                    type="button"
                                    onClick={handleDownloadClick}
                                    sx={{
                                        p: '0.4rem',
                                        color: 'white',
                                        borderRadius: '1.1rem',
                                        backgroundColor: '#1F274A',
                                        ":hover": {
                                            backgroundColor: '#1F274A',
                                        }
                                    }}
                                >
                                    Download
                                </LoadingButton> */}
                                </DialogActions>
                            </Box>
                    }
                    {
                        (guestData && qrCodeDialog) ?
                            <EventQrCodeDialog
                                handleClose={handleQrCodeClose}
                                eventDetailsObj={eventData}
                                scanPermission={scanPermission}
                                guestDetails={guestData}
                                maximumValueObj={maxValueObj}
                                ticketDetails={ticketData}
                                checkInDetails={checkInData}
                            /> :
                            null
                    }
                    {openPrintDialog && (
                        <DialogBox open={true} handleClose={handlePrintDialogClose}
                            title='Print Guest Details' content={<GuestDetailsTable guestDetails={guestDetails} additionalDetails={additionalDetails} eventData={eventData} isPrint={isPrint} isDownload={isDownload} />}
                        />
                    )}
                </>
            }
        </>
    )
}

const GuestDialog = ({ handleClose, guestDetails, additionalDetails }) => {
    return (
        <DialogBox open={true} handleClose={handleClose}
            title='Guest details' content={GuestDetailContent(guestDetails, additionalDetails, handleClose)}
        />
    )
};

export default GuestDialog;
