import MenuIcon from '@mui/icons-material/Menu';
import { Box, Slide, Toolbar, Typography, useMediaQuery, useScrollTrigger } from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from "react-router-dom";
import DashboardDrawer from '../../components/DashboardDrawer';
import { change_sidebar_status } from '../../redux/slices/sidebarDataSlice';
import { userDetails } from "../../redux/slices/userDataSlice";
import { mapUrlToText } from "../../utils/functions/getActiveTab";
import AvtarMenu from "./AvtarMenu";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const HideOnScroll = (props) => {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true // Disable hysteresis to trigger immediately when scrolling down
    });
    return (

        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const DashboardLayout = (props) => {
    const x = useLocation();
    const { window } = props;
    const dispatch = useDispatch();
    const isSmallDevices = useMediaQuery("(max-width:900px)");
    const [webOpen, setWebOpen] = useState(true);
    const [mobileOpen, setMobileOpen] = useState(false);

    const user_details = useSelector(userDetails)

    const handleMobileDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleWebDrawerToggle = () => {
        setWebOpen(!webOpen);
        dispatch(change_sidebar_status(!webOpen));
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <HideOnScroll {...props}>
                    <AppBar position="fixed" open={isSmallDevices ? mobileOpen : webOpen}
                        sx={{
                            width: isSmallDevices ? '100%' :
                                ((webOpen && user_details) ? `calc(100% - ${drawerWidth}px)` : '100%'),
                            backgroundColor: '#E5E4E9',
                            pr: '0 !important'
                        }}
                    >
                        <Toolbar>
                            {user_details ?
                                <>
                                    {isSmallDevices ?
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleMobileDrawerToggle}
                                            sx={{
                                                mr: { xs: 1, sm: 2 },
                                                color: 'black'
                                            }}
                                        >
                                            <MenuIcon />
                                        </IconButton> :
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleWebDrawerToggle}
                                            sx={{
                                                mr: { xs: 1, sm: 2 },
                                                ...(webOpen && { display: 'none' }),
                                                color: 'black'
                                            }}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    }
                                </>
                                : null
                            }
                            <Typography
                                variant="h3"
                                component="div"
                                sx={{ display: { xs: 'block', sm: 'block' }, color: 'black' }}
                            >
                                {mapUrlToText(x.pathname)}
                            </Typography>
                            {user_details ?
                                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <Typography variant="h4" sx={{ color: 'black', textTransform: 'capitalize' }}>
                                        {user_details?.first_name}
                                    </Typography>
                                    <AvtarMenu userDetails={user_details} />
                                </Box>
                                : null
                            }
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>

                {user_details ?
                    <>
                        {isSmallDevices ?
                            <Box component="nav">
                                <Drawer
                                    open={mobileOpen}
                                    variant="temporary"
                                    container={container}
                                    onClose={handleMobileDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                        msOverflowStyle: 'none',
                                        scrollbarWidth: 'none',
                                        '& .MuiDrawer-paper': {
                                            color: 'white',
                                            width: drawerWidth,
                                            boxSizing: 'border-box',
                                            backgroundColor: '#10163A'
                                        },
                                    }}
                                >
                                    <DashboardDrawer handleMobileDrawerToggle={handleMobileDrawerToggle} />
                                </Drawer>
                            </Box> :
                            <Box
                                component="nav"
                                sx={{ width: webOpen ? drawerWidth : 'none', flexShrink: 0 }}
                            >
                                <Drawer
                                    open={webOpen}
                                    anchor="left"
                                    variant="persistent"
                                    sx={{
                                        flexShrink: 0,
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                        msOverflowStyle: 'none',
                                        scrollbarWidth: 'none',
                                        '& .MuiDrawer-paper': {
                                            boxSizing: 'border-box',
                                            width: drawerWidth,
                                            color: 'white',
                                            backgroundColor: '#10163A',
                                        }
                                    }}
                                >
                                    <DashboardDrawer
                                        handleMobileDrawerToggle={handleMobileDrawerToggle}
                                        handleWebDrawerToggle={handleWebDrawerToggle}
                                    />
                                </Drawer>
                            </Box>
                        }
                    </>
                    : null
                }

                <Box component="main"
                    sx={{
                        flexGrow: 1,
                        p: { xs: 0, sm: 1, md: 2 },
                        width: { md: `calc(100% - ${drawerWidth}px)` }
                    }}>
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </>
    )
}

export default DashboardLayout