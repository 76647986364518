import { Box, Button, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography, useMediaQuery } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GroupSettingsContext } from "../..";
import CostBreakdown from '../../../../../../../../components/CostBreakdown';
import { groupDetails } from "../../../../../../../../redux/slices/groupDetailSlice";
import { addGroupSubscription, editGroupSubscription } from "../../../../../../../../services/wGroups/groupSubscription";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { gstOptions } from '../../../../../../../../utils/constants/ticketgstoptions';
import { validityPeriods } from "../../../../../../../../utils/constants/validityPeriods";
import { getExtraChargesCosting } from '../../../../../../../../utils/functions/getExtraChargesCosting';
import { parseDecimalNumber } from '../../../../../../../../utils/functions/getFormattedNumber';
import './tinymce.scss';

const CreateSubscription = ({ edit, subscription, handleEditClose }) => {
    const isSmallDevice = useMediaQuery("(max-width:600px)"); // Smaller than tablets
    const groupData = useSelector(groupDetails);
    const { activeSlide, setSlide, setSubscriptions } = useContext(GroupSettingsContext);

    const editorRef = useRef(null);
    const defaultCurrencyValue = edit ? subscription.currency : ticketCurrencies[0].value;
    const defaultGstValue = edit ? subscription.gst_percent : gstOptions[0].value;
    const validityPeriodValue = edit ? subscription.validity_type : validityPeriods[0].value;

    const { handleSubmit, register, setValue, control, formState: { errors }, watch, reset } = useForm({
        defaultValues: {
            currency: edit ? subscription.currency : ticketCurrencies[0].value, //To show gst option only for rupees
            amount: edit ? parseDecimalNumber(subscription.amount, 2) : 0, // To show cost breakdown from component mount
            validity_type: edit ? subscription?.validity_type : 'day',
            gst_percent: edit ? subscription.gst_percent : 0, // To show GST row in cost breakdown on edit if GST enabled 
            extra_charges_from_buyer: edit ? subscription.extra_charges_from_buyer : 0 // To show GST row in cost breakdown on edit if GST enabled 
        }
    });

    let subscriptionPrice = _.round(watch('amount'), 2);
    let gst_percent = watch('gst_percent');
    const charges = getExtraChargesCosting(subscriptionPrice, gst_percent);
    const subscriptionPriceSymbol = ticketCurrencies.find((t) => t.value === watch('currency'))?.symbol

    const mySubmit = (data) => {
        if (editorRef.current.getContent().length > 0) { // For terms & conditions
            data.terms_and_conditions = editorRef.current.getContent();
        }
        if (data.validity_type === 'lifetime') { // For lifetime subscriptions
            data.validity_time = -1;
        }
        data['extra_charges_from_buyer'] = +data['extra_charges_from_buyer'];
        const gstOnSubscriptionPrice = (data.gst_percent / 100) * subscriptionPrice;

        data.amount = parseFloat(data.amount);
        data.gst_on_subscription_price = parseFloat(gstOnSubscriptionPrice);
        data.system_charges = parseFloat(charges.systemFee - charges.wowslyGst);
        data.wowsly_gst = parseFloat(charges.wowslyGst);
        data.razor_pay_fee = parseFloat(charges.razorPayFee);
        data.purchase_price = parseFloat(data['extra_charges_from_buyer'] ?
            data.amount + data.gst_on_subscription_price + data.razor_pay_fee + charges.systemFee :
            data.amount + data.gst_on_subscription_price, 2);
        data.amount_to_owner = parseFloat(data['extra_charges_from_buyer'] ?
            data.amount + data.gst_on_subscription_price :
            data.amount + data.gst_on_subscription_price - (data.razor_pay_fee + charges.systemFee), 2);

        if (edit) {
            data['_method'] = 'PUT';
            editGroupSubscription(data, groupData?.id, subscription.id).then((res) => {
                handleEditClose();
                setSubscriptions((subscriptions) => {
                    let i = subscriptions.findIndex((t) => t.id === subscription.id);
                    subscriptions[i] = res.data.data;
                    return [...subscriptions];
                })
                toast.success("Group subscription updated", {
                    position: "top-right",
                    theme: "dark"
                });
            }).catch((err) => {
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        } else {
            addGroupSubscription(data, groupData?.id).then((res) => {
                toast.success("Group subscription created successfully", {
                    position: "top-right",
                    theme: "dark"
                });
                setSubscriptions((subscriptions) => {
                    return [...subscriptions, res.data.data];
                })
                setSlide(1);
            }).catch((err) => {
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            })
        }
    };

    useEffect(() => {
        return () => {
            reset()
        };
    }, [activeSlide]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={true} sx={{
                    p: { xs: '1rem', md: '1.5rem 1.5rem' },
                    height: edit ? '22rem' : { xs: '26rem', md: '31rem' },
                }}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            color="warning"
                            label="Subscription Title"
                            defaultValue={edit ? subscription.title : null}
                            error={errors?.title ? true : false}
                            helperText={
                                errors?.title ? "This is required field" : null
                            }
                            {...register("title", { required: true })}
                        />

                        <Stack direction='row' spacing={2}>
                            <Controller
                                name="currency"
                                control={control}
                                defaultValue={defaultCurrencyValue}
                                render={({ field: { onChange, value } }) => (
                                    <Select value={value} onChange={(event) => {
                                        // Custom logic here
                                        const selectedValue = event.target.value;
                                        if (selectedValue !== 'rupees') {
                                            setValue('gst_percent', 0);
                                        }

                                        // Call the original onChange function
                                        onChange(event);
                                    }}>
                                        {ticketCurrencies.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <TextField
                                fullWidth
                                color="warning"
                                label="Subscription Amount"
                                defaultValue={edit ? subscription.amount : null}
                                error={errors?.amount ? true : false}
                                helperText={
                                    errors?.amount ? "This is required field" : null
                                }
                                {...register("amount", { required: true })}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            {watch('currency') === 'rupees' ?
                                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                        GST on Subscription Amount
                                    </Typography>
                                    <Controller
                                        name="gst_percent"
                                        control={control}
                                        defaultValue={defaultGstValue}
                                        render={({ field: { onChange, value } }) => (
                                            <Select size="small" value={value} onChange={onChange} color="warning">
                                                {gstOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </Stack>
                                : null
                            }

                            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    Additional Charges Paid by Buyer?
                                </Typography>
                                <Controller
                                    control={control}
                                    name="extra_charges_from_buyer"
                                    defaultValue={edit ? (subscription.extra_charges_from_buyer ? true : false) : false}
                                    render={(props) => (
                                        <Switch
                                            color="warning"
                                            onChange={(e) => props.field.onChange(e.target.checked)}
                                            checked={props.field.value ? true : false}
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>

                        <CostBreakdown
                            model='Subscription'
                            watch={watch}
                            charges={charges}
                            gstPercent={gst_percent}
                            priceSymbol={subscriptionPriceSymbol}
                            actualPrice={subscriptionPrice}
                        />

                        <Box>
                            <Typography sx={{ fontSize: '0.95rem', mb: 2, color: 'rgba(0, 0, 0, 0.6)' }}>
                                Subscription Validity
                            </Typography>
                            <Stack direction={isSmallDevice ? 'column' : 'row'} spacing={2}>
                                {watch('validity_type') !== 'lifetime' ?
                                    <TextField
                                        color="warning"
                                        label={isSmallDevice ?
                                            `${watch('validity_type')}` :
                                            `Number of ${validityPeriods.find(vp => vp.value === watch('validity_type')).label}`
                                        }
                                        inputProps={{ type: 'number', min: 1, max: 999 }}
                                        defaultValue={edit ? subscription.validity_time : null}
                                        error={errors?.validity_time ? true : false}
                                        helperText={
                                            errors?.validity_time ? "This is required field" : null
                                        }
                                        sx={{ width: { xs: '100%', sm: '50%' } }}
                                        {...register("validity_time", { required: true })}
                                    />
                                    : null
                                }
                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Validity Type</InputLabel>
                                    <Select
                                        defaultValue={validityPeriodValue}
                                        labelId="demo-simple-select-helper-label"
                                        label="Validity type"
                                        {...register('validity_type', { required: true })}
                                    >
                                        {validityPeriods?.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Box>

                        <TextField
                            rows={3}
                            multiline
                            fullWidth
                            color="warning"
                            label="Subscription Details"
                            defaultValue={edit ? subscription.description : null}
                            error={errors?.description ? true : false}
                            helperText={
                                errors?.description ? "This is required field" : null
                            }
                            {...register("description", { required: true })}
                        />

                        <Box>
                            <Typography sx={{ fontSize: '0.95rem', mb: 2, color: 'rgba(0, 0, 0, 0.6)' }}>
                                Terms & Conditions
                            </Typography>
                            <Editor
                                apiKey="dsiirqxfbbshbtto49hmn3aplhh22hnefwjei883n0nyg0no"
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={edit ? subscription?.terms_and_conditions : null}
                                init={{
                                    selector: 'textarea',
                                    content_css: './tinymce.css',
                                    height: '300px',
                                    plugins: ['lists', 'autolink', 'link', 'preview', 'quickbars'],
                                    menubar: false,
                                    toolbar: 'bullist numlist | bold italic underline forecolor | undo redo | fontfamily fontsize | ' +
                                        'alignleft aligncenter alignright | preview',
                                    quickbars_insert_toolbar: false,
                                    toolbar_mode: 'sliding',
                                    font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                    quickbars_selection_toolbar: 'bold italic underline forecolor | formatselect | quicklink',
                                }}
                            />
                        </Box>
                    </Stack>
                </DialogContent>

                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button variant="outlined" color='warning' onClick={() => reset()}>
                        Reset
                    </Button>
                    <Button type="submit" color='warning' variant="contained"
                        sx={{ boxShadow: 'none' }}>
                        {edit ? "Save" : "Create"}
                    </Button>
                </DialogActions>
            </form>
        </>
    )
};

export default CreateSubscription;
