import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import CostBreakdown from "../../../../../../../../components/CostBreakdown";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { getExtraChargesCosting } from "../../../../../../../../utils/functions/getExtraChargesCosting";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";

const ViewSubscription = ({ subscription, setAction }) => {
    const actualSubscriptionPrice = parseDecimalNumber(subscription.amount, 2);
    const charges = getExtraChargesCosting(actualSubscriptionPrice, subscription.gst_percent);
    const subscriptionPriceSymbol = ticketCurrencies.find((t) => t.value === subscription.currency)?.symbol;

    const handleViewClose = () => {
        setAction((action) => ({ ...action, 'view': false }))
    }
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleViewClose}
                PaperProps={{ sx: { width: { xs: '80%', sm: '70%', md: '50%' }, m: { xs: 0 } } }}
            >
                <DialogTitle sx={{ p: '0.5rem 1rem' }}>
                    <IconButton onClick={() => handleViewClose()} sx={{ position: 'absolute', right: '0.2rem', top: '0.2rem' }}>
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                        Subscription Details
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Stack spacing={1} sx={{ width: '100%' }}>
                        <Box>
                            <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Name: </Typography>
                            <Typography sx={{ ml: 1, fontWeight: 'bold', fontSize: '1rem', display: 'inline-block' }}>{subscription.title}</Typography>
                        </Box>

                        <Box>
                            <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Validity: </Typography>
                            {subscription?.validity_type === 'lifetime' ?
                                <Typography sx={{ color: '#1F274A', fontSize: '0.9rem', ml: 1, display: 'inline-block' }}>
                                    Lifetime
                                </Typography>
                                :
                                <Typography sx={{ color: '#1F274A', fontSize: '0.9rem', ml: 1, display: 'inline-block' }}>
                                    {subscription.validity_time} {subscription.validity_type}
                                </Typography>
                            }
                        </Box>

                        {subscription.description ?
                            <Box>
                                <Typography sx={{ fontWeight: '500', fontSize: '15px', display: 'inline-block' }}>Details:</Typography>
                                <Typography sx={{ ml: 1, color: '#6F738B', display: 'inline-block', fontSize: '.9rem' }}>{subscription.description}</Typography>
                            </Box>
                            : null
                        }

                        <Divider sx={{ my: 1 }} />

                        <Typography sx={{ my: 2, color: '#1F274A', fontSize: '1.2rem', fontWeight: 'bold' }}>
                            Price: {parseDecimalNumber(subscription.amount, 3)} <span dangerouslySetInnerHTML={{ __html: subscriptionPriceSymbol }} />
                        </Typography>
                        <CostBreakdown
                            model='Subscription'
                            modelObject={subscription}
                            charges={charges}
                            actualPrice={actualSubscriptionPrice}
                            gstPercent={subscription.gst_percent}
                            priceSymbol={subscriptionPriceSymbol}
                        />

                        {subscription.extra_charges_from_buyer ?
                            <Typography sx={{ color: '#6F738B', fontSize: '.9rem' }}>Buyer will pay extra charges on this price</Typography>
                            : null
                        }

                        {subscription.terms_and_conditions ?
                            <>
                                <Divider sx={{ py: 0.5 }} />
                                <Box sx={{ pt: '0.5rem' }}>
                                    <Typography sx={{ fontSize: '15px' }}>T & C</Typography>
                                    <Editor
                                        apiKey="dsiirqxfbbshbtto49hmn3aplhh22hnefwjei883n0nyg0no"
                                        initialValue={subscription.terms_and_conditions}
                                        disabled={true}
                                        init={{
                                            height: '10rem',
                                            readonly: 1,
                                            menubar: false,
                                            toolbar: false,
                                        }}
                                    />
                                </Box>
                            </>
                            : null
                        }

                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
};

export default ViewSubscription;
