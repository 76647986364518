import { Card, List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { EventDetailsContext } from '../..';
import MuiTabPanel from "../../../../components/muiTabPanel";
import { eventDetails } from "../../../../redux/slices/eventDataSlice";
import { ALL_PERMISSION_ROLES } from "../../../../utils/constants/roles";
import { mapEventTabs } from '../../../../utils/functions/mapEventTabs';
import { userStatus } from '../../../../redux/slices/userDataSlice';
import CreateEditViewDialog from './CreateEditViewDialog';
import { Box } from '@mui/system';
import { capitalize } from 'lodash';
import { EventReports } from './components/EventReports';
import SubEvents from './components/SubEvents';

const useStyles = makeStyles({
    root: {
        "&.Mui-selected": {
            color: "#FF8359 !important",
            opacity: "1 !important",
            fontWeight: "bold !important"
        },
    },
});

const EventDetailTabs = ({ tabValue, handleTabChange }) => {
    const eventData = useSelector(eventDetails);
    const isLoggedIn = useSelector(userStatus);
    const { refs, subSection } = useContext(EventDetailsContext);
    const renderSubSection = (subSection) => {
        switch (subSection) {
            case 'event-overview':
                return (
                    <EventTabsWindow
                        eventData={eventData}
                        tabValue={tabValue}
                        handleTabChange={handleTabChange}
                        isLoggedIn={isLoggedIn}
                        refs={refs}
                    />
                );
            case 'event-reports':
                return (
                    <EventReports
                        cardData={[
                            { title: 'Registrations', body: { Count: eventData?.eventuserCount ?? "0" } },
                            { title: 'Tickets Booked', body: { Count: eventData?.totalBooked ?? "0" } },
                            { title: 'Ticket Revenue', body: { Amount: eventData?.ticketRevenue ?? "0" } },
                            // { title: 'RSVP', body: { Yes: '600', No: 167, Maybe: 233 } },
                            { title: 'Event Check-ins', body: { Count: eventData?.totalCheckIns ?? "0" } },
                            // { title: 'Bank Credit', body: { Amount: '₹58,000' } },
                        ]}
                        refs={refs}
                    />
                );
            case 'microsite-builder':
                return (
                    <SubEvents views={eventData.tabs?.filter((tab) => tab.title === 'Info')[0]?.views ?? []} />
                );
            default:
                return (
                    <Box>
                        <Typography>
                            {capitalize(subSection)}
                        </Typography>
                    </Box>
                );
        }
    };
    return (
        <>
            {subSection ?
            // {false ?
                renderSubSection(subSection)
                :
                <EventTabsWindow
                    eventData={eventData}
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}
                    isLoggedIn={isLoggedIn}
                    refs={refs}
                />
            }
        </>
    )
};



const EventTabsWindow = ({ eventData, tabValue, handleTabChange, isLoggedIn, refs }) => {
    const c = useStyles();
    return (
        Object.keys(eventData).length > 0 ?
            <>
                <Stack
                    direction='row'
                    alignItems='center'
                    className='menubar-sticky'
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        // scrollable
                        scrollButtons={false}
                        // breakpoint="sm"
                        // allowScrollButtonsMobile
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#FF8359"
                            }
                        }}
                        sx={{
                            // overflowX: 'auto',
                            width: '80vw !important',
                            flexGrow: 1,
                            backgroundColor: '#FFEBE4',
                            maxWidth: { sm: '100%' },
                        }}
                    >
                        {eventData.tabs.map((tab, index) => (
                            <Tab id={tab.title}
                                ref={index === 1 ? refs.guestsTabRef : null} // TODO: Change this logic when tabs will have dynamic order
                                key={index} className={c.root}
                                label={tab.title === 'Events' ? 'Info' : tab.title}
                                sx={{
                                    color: '#FF8359',
                                    fontWeight: 'bold',
                                    opacity: '1',
                                    textTransform: 'none',
                                }}
                            />
                        )
                        )}
                    </Tabs>
                    {ALL_PERMISSION_ROLES.includes(eventData?.current_user_role) ?
                        <CreateEditViewDialog views={eventData.tabs[0].views} /> : null
                    }
                </Stack>

                {eventData.tabs.map((tab, index) => (
                    <MuiTabPanel key={index} value={tabValue} index={index}>
                        {mapEventTabs(tab?.title, tab?.views, isLoggedIn)}
                        {/* This will render relative component based on event API response */}
                    </MuiTabPanel>
                ))}
            </> :
            <Skeleton height='10rem' sx={{ mx: 2 }} />
    );
}
export default EventDetailTabs;
