import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Stack,
    CircularProgress,
    Pagination,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Button,
    Divider
} from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { eventDetails } from '../../../../../../../../redux/slices/eventDataSlice';
import { downloadEventPollReports, getEventPollReports } from '../../../../../../../../services/wEvents/event';
import PollReportsTab from '../../../../../Menubar/components/EventPolls/components/PollReportsTab';
import dayjs from 'dayjs';
import moment from 'moment';

const PFormReports = ({ pollId, handlePollInfoBackClick }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pollReports, setPollReports] = useState([]);
    const [poll, setPoll] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const eventData = useSelector(eventDetails);

    useEffect(() => {
        const fetchPollReports = async () => {
            try {
                const response = await getEventPollReports(eventData?.id, pollId, page);
                const data = response.data.data;
                setPoll(data.poll);
                setPollReports(data.answers.data);
                setTotalPages(data.answers.last_page);
            } catch (err) {
                setError('Failed to load poll reports');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        if (eventData?.id) {
            fetchPollReports();
        }
    }, [eventData?.id, page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [dateTime, setDateTime] = useState({
        start_date: null,
        end_date: null
    });

    const handleDownload = () => {
        if (dateTime.end_date && dateTime.start_date >= dateTime.end_date) {
            toast.error("Start date must be less than end date", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }

        setDownloadLoading(true);

        downloadEventPollReports(eventData?.id, pollId)
            .then((response) => {
                const fileBlob = new Blob([response.data], { type: "text/csv" });
                const downloadLink = document.createElement("a");
                document.body.appendChild(downloadLink);
                const fileUrl = URL.createObjectURL(fileBlob);

                downloadLink.href = fileUrl;
                downloadLink.download = `${eventData.title}-PollReports-${moment().format("D-M-YYYY HH-mm")}.csv`;
                downloadLink.click();

                URL.revokeObjectURL(fileUrl);
                document.body.removeChild(downloadLink);
                setDownloadLoading(false);
                // handleDialogClose();
            })
            .catch((err) => {
                console.error('Failed to download poll reports', err);
                setDownloadLoading(false);
                toast.error("Something went wrong", {
                    position: "top-right",
                    theme: "dark"
                });
                // handleDialogClose();
            });
    };

    const ListItemSkeleton = () => (
        <ListItem>
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton width="60%" />}
                secondary={<Skeleton width="40%" />}
            />
        </ListItem>
    );

    if (loading) return (
        <Box display="flex" justifyContent="center" p={3} sx={{ flexWrap: 'wrap' }}>
            {[1, 2, 3, 4, 5,].map(() => (<ListItemSkeleton />))}
        </Box>
    );

    if (error) return (
        <Typography color="error" p={2}>{error}</Typography>
    );

    return (
        <Box sx={{ width: '100%', px: '2.5rem' }}>
            {/* <Box sx={{ textAlign: 'center', mb: '1.5rem' }}>
                <LoadingButton
                    loading={downloadLoading}
                    loadingPosition="start"
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    color='warning'
                    sx={{ boxShadow: 'none' }}
                    onClick={handleDownload}
                >
                    Download Reports
                </LoadingButton>
            </Box> */}
            <PollReportsTab pollId={pollId} handleBackClick={handlePollInfoBackClick} handleDownload={handleDownload} />
            {/* <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
                color="warning"
                sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
            /> */}
        </Box>
    );
};

export default PFormReports;